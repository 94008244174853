import React, {useState, useEffect} from 'react'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import Icon from 'components/utils/Icon'

import 'styles/app.css'

import { filterData } from 'scripts/common'

import { selectData } from 'scripts/offline'

const JobAndGrade = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [searchValue, setSearchValue] = useState('')

  // const [isModal, setIsModal] = useState({
  //   offline: false
  // })

  const fetchData = () => {

    const fetching = () => {

      fetch('/api/selectMenuJobAndGrade', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userLevel: props.user === undefined ? '' : props.user.userlevel,
          guestAccess: props.user === undefined ? '' : props.user.guestaccess
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)
          setFetchedData(result)
        },
        (error) => console.log('Error: selectMenuJobAndGrade --> ' + error)
      )

    }

    selectData('JobAndGrade')
    .then(res => res.length > 0 ? setFetchedData(res.sort((a, b) => parseFloat(b.jobnumber) - parseFloat(a.jobnumber))) : fetching())
    .catch(err => fetching())


    // const fetch = () => {
    //   fetch('/api/selectMenuJobAndGrade', {
    //     method: 'post',
    //     headers: {
    //       'Accept': 'application/json, text/plain, */*',
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({
    //       userLevel: props.user === undefined ? '' : props.user.userlevel,
    //       guestAccess: props.user === undefined ? '' : props.user.guestaccess
    //     })
    //   })
    //   .then(res=>res.json())
    //   .then(
    //     (result) => {
    //       //console.log('result: ' + result)
    //       setFetchedData(result)
    //     },
    //     (error) => console.log('Error: selectMenuJobAndGrade --> ' + error)
    //   )
    // }

    // const fetch = () => {
    //   selectData('JobAndGrade')
    //   .then(res => {
    //     setFetchedData(res.sort((a, b) => parseFloat(b.jobnumber) - parseFloat(a.jobnumber)))
    //   })
    //   .catch(res => {
    //     fetch()
    //   })
    // }



    //if (props.user.offline === props.filter.jobNumber) {

      // selectData('JobAndGrade')
      // .then(res => {
      //   setFetchedData(res.sort((a, b) => parseFloat(b.jobnumber) - parseFloat(a.jobnumber)))
      // })
      // .catch(res => {
      //   fetch('/api/selectMenuJobAndGrade', {
      //     method: 'post',
      //     headers: {
      //       'Accept': 'application/json, text/plain, */*',
      //       'Content-Type': 'application/json'
      //     },
      //     body: JSON.stringify({
      //       userLevel: props.user === undefined ? '' : props.user.userlevel,
      //       guestAccess: props.user === undefined ? '' : props.user.guestaccess
      //     })
      //   })
      //   .then(res=>res.json())
      //   .then(
      //     (result) => {
      //       //console.log('result: ' + result)
      //       setFetchedData(result)
      //     },
      //     (error) => console.log('Error: selectMenuJobAndGrade --> ' + error)
      //   )
      // })
    //} else {

      // selectData('JobAndGrade').then(res => {
      //   setFetchedData(res.sort((a, b) => parseFloat(b.jobnumber) - parseFloat(a.jobnumber)))
      // })

      // fetch('/api/selectMenuJobAndGrade', {
      //   method: 'post',
      //   headers: {
      //     'Accept': 'application/json, text/plain, */*',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     userLevel: props.user === undefined ? '' : props.user.userlevel,
      //     guestAccess: props.user === undefined ? '' : props.user.guestaccess
      //   })
      // })
      // .then(res=>res.json())
      // .then(
      //   (result) => {
      //     //console.log('result: ' + result)
      //     setFetchedData(result)
      //   },
      //   (error) => {
      //     console.log('Error: selectMenuJobAndGrade --> ' + error)
      //
      //     selectData('JobAndGrade').then(res => {
      //       setFetchedData(res.sort((a, b) => parseFloat(b.jobnumber) - parseFloat(a.jobnumber)))
      //     })
      //   }
      // )

    //}

  }

  useEffect(() => {
    fetchData()
  }, [])

  const search = (e) => setSearchValue(e.target.value)

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  // const toggleOffline = () => setIsModal(prevState =>({...prevState,
  //   offline: isModal.offline ? false : true
  // }))

  const syncing = () => alert('I am syncing...')
  const synced = () => alert('I am synced...')

  let listOfData = fetchedData.map(data => {

    let jn = data.jobnumber === null ? '' : data.jobnumber
    let gd = data.gradeid === null ? '' : data.gradeid === 'RG' ? 'Grading' : data.gradeid === 'PG' ? 'Post' : ''
    let project = data.project === null ? '' : data.project
    let company = data.company === null ? '' : data.company
    let city = data.city === null ? '' : data.city
    let latitude = data.latitude === null ? '' : data.latitude
    let longitude = data.longitude === null ? '' : data.longitude

    // let offline = props.user.offline === jn ? true : false
    // let offlineIcon =
    // jn && offline === 'sync' ?
    // <td><Icon name='sync' onClick={props.selectOffline} /></td> :
    // jn && offline === 'syncing' ?
    // <td><div className='sync'><Icon name='sync' onClick={syncing} /></div></td> :
    // jn && offline === 'synced' ?
    // <td><Icon name='offline_pin' onClick={synced} /></td> :
    // <td><Icon name='download_for_offline' outline={true} onClick={props.selectOffline} /></td>

    let filter = filterData(data, searchValue)

    if (filter) {
      //<td style={{display: 'none'}}></td>
      //{offlineIcon}
      return (
        <tr onClick={props.selectJob}>
          <td style={{display: 'none'}}></td>
          <td style={{display: 'none'}}>{latitude}</td>
          <td style={{display: 'none'}}>{longitude}</td>
          <td>{jn}</td>
          <td>{gd}</td>
          <td>{project}</td>
          <td>{company}</td>
          <td>{city}</td>
        </tr>
      )
    }

  })

  let content = (
    <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

      <SearchBar search={search} searchValue={searchValue} clearSearch={clearSearch} />

      <div style={{textAlign: 'center'}}>
        <div style={{
          border: '2px solid tomato',
          borderRadius: 5,
          color: 'tomato',
          cursor: 'pointer',
          padding: 5,
          margin: 10
        }} onClick={props.clearJob}>Clear Job Number
        </div>
      </div>

      <>

        <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

          <table>

            <thead>
              <tr>

                <th>JN</th>
                <th>GD</th>
                <th>Project</th>
                <th>Client</th>
                <th>City</th>
              </tr>
            </thead>

            <tbody>
              {listOfData}
            </tbody>

          </table>

        </div>

      </>

    </div>
  )

  return <Modal content={content} closeModal={props.closeModal} />
}

export default JobAndGrade
