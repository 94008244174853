import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import CircleButton from 'components/utils/CircleButton'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import ViewLocation from 'components/utils/ViewLocation'
import JobAndGrade from 'components/menu/JobAndGrade'
import SearchBar from 'components/utils/SearchBar'

import { formatDateTime, formatDateYMD, formatTime, getLocation, catchError } from 'scripts/common'

import { addPending, selectData } from 'scripts/offline'

const Status = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [isModal, setIsModal] = useState({
    add: false,
    edit: false,
    job: false,
    history: false
  })

  const isChanged = useRef(false)
  const [searchValue, setSearchValue] = useState('')

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    status: '',
    jobNumber: '',
    gradeId: ''
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    status: '',
    jobNumber: '',
    gradeId: ''
  })

  const fetchData = () => {

    if (props.user.offline && props.user.offlineJob === props.filter.jobNumber) {

      selectData('Statuses').then(res => {
        setFetchedData(res.sort((a, b) => new Date(a.entrytime) - new Date(b.entrytime)))
      })

    } else {

      fetch('/api/selectStatuses', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          by: props.user.username,
          time: formatDateTime(new Date())
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))
          setFetchedData(result)
        },
        (error) => catchError('', '', '', 'selectStatuses', JSON.stringify(error), props.user.username, props.user.device)

      )

    }

  }

  useEffect(() => {
    fetchData()
  }, [])

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const selectRow = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      setIsValidated(prevState => ({...prevState,
        entryby: fetchedData[i].entryby,
        entrytime: fetchedData[i].entrytime,
        entrylat: fetchedData[i].entrylat,
        entrylng: fetchedData[i].entrylng,
        entrydevice: fetchedData[i].entrydevice,
        modby: fetchedData[i].modby,
        modtime: fetchedData[i].modtime,
        modlat: fetchedData[i].modlat,
        modlng: fetchedData[i].modlng,
        moddevice: fetchedData[i].moddevice,
        id: fetchedData[i].id,
        status: fetchedData[i].status,
        jobNumber: fetchedData[i].jobnumber,
        gradeId: fetchedData[i].gradeid
      }))
      openEdit()

    }

  }

  const changedData = () => isChanged.current = true

  const addStatus = (e) => {

    const addToServer = () => {

      getLocation(function(latlng){

        fetch('/api/addStatus', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            status: isValidated.status,
            jobnumber: isValidated.jobNumber !== '' && isValidated.jobNumber !== null ? isValidated.jobNumber : props.filter.jobNumber,
            gradeid: isValidated.gradeId !== '' && isValidated.gradeId !== null ? isValidated.gradeId : props.filter.gradeId
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {

            //if (props.myNuke.length > 0) if (isValidated.status === 'End Day' && (props.myNuke[0].checkIn === '' || props.myNuke[0].checkIn === null)) props.checkInNuke()

            props.updateParent()
            fetchData() // i need the id if edited
            isChanged.current = false
            props.closeModal()
            //alert('Added.')

          },
          (error) => {

            alert('Error: could not add Status. Contact and admin.')
            console.log('Error: addStatus --> ' + error)
          }
        )

      })

    }

    if (isValidated.status === '' || isValidated.status === null) {
      alert('Please select a status')
    } else if ((isValidated.status === 'Arrive Job' || isValidated.status === 'Depart Job') && ((props.filter.jobNumber === '' || props.filter.jobNumber === null) && (isValidated.jobNumber === '' || isValidated.jobNumber === null))) {
      alert("Please select a Job Number")
    } else {

      if (props.user.bufferData) {

        let id = Math.max(...fetchedData.map(o => o.id)) + 1

        addPending({
          actionId: 1,
          action: 'addStatus',
          table: 'Statuses',
          jobnumber: isValidated.jobNumber !== '' && isValidated.jobNumber !== null ? isValidated.jobNumber : props.filter.jobNumber,
          gradeid: isValidated.gradeId !== '' && isValidated.gradeId !== null ? isValidated.gradeId : props.filter.gradeId,
          entryby: props.user.username,
          entrytime: formatDateTime(new Date()),
          entrylat: '',
          entrylng: '',
          entrydevice: props.user.device,
          modby: '',
          modtime: null,
          modlat: '',
          modlng: '',
          moddevice: '',
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: '',
          lng: '',
          device: props.user.device,
          id: id,
          status: isValidated.status
        })
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(prevState =>
              [
                {
                  entryby: props.user.username,
                  entrytime: formatDateTime(new Date()),
                  entrylat: '',
                  entrylng: '',
                  entrydevice: props.user.device,
                  id: id,
                  status: isValidated.status,
                  jobNumber: isValidated.jobNumber,
                  gradeId: isValidated.gradeId
                },
                ...prevState
              ]
            )

            props.updateStatus([{status: isValidated.status, entrytime: formatDateTime(new Date())}])
            //props.updateParent()
            isChanged.current = false
            props.closeModal()

          },
          (error) => {

            addToServer()
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addStatus', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      } else {

        addToServer()

      }

    }

  }

  const editStatus = () => {

    const addToServer = () => {

      getLocation(function(latlng){

        fetch('/api/editStatus', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            id: isValidated.id,
            status: isValidated.status,
            jobnumber: isValidated.jobNumber,
            gradeid: isValidated.gradeId
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            //if (props.myNuke.length > 0) if (isValidated.status === 'End Day' && (props.myNuke[0].checkIn === '' || props.myNuke[0].checkIn === null)) props.checkInNuke()

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                status: isValidated.status,
                jobnumber: isValidated.jobNumber,
                gradeid: isValidated.gradeId
              } :
              data
            ))

            props.updateParent()
            isChanged.current = false
            closeModal()

          },
          (error) => {

            alert('Error: could not edit Status. Contact and admin.')
            console.log('Error: editStatus --> ' + error)
          }
        )

      })

    }

    if ((isValidated.status === 'Arrive Job' || isValidated.status === 'Depart Job') && isValidated.jobNumber === '') {
        alert("Please select a Job Number")
    } else {

      if (props.user.bufferData) {

        addPending({
          actionId: 2,
          action: 'editStatus',
          table: 'Statuses',
          jobnumber: props.filter.jobNumber,
          gradeid: props.filter.gradeId,
          entryby: props.user.username,
          entrytime: formatDateTime(new Date()),
          entrylat: '',
          entrylng: '',
          entrydevice: props.user.device,
          modby: '',
          modtime: null,
          modlat: '',
          modlng: '',
          moddevice: '',
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: '',
          lng: '',
          device: props.user.device,
          id: isValidated.id,
          status: isValidated.status
        })
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: '',
                modlng: '',
                moddevice: props.user.device,
                status: isValidated.status,
                jobNumber: isValidated.jobNumber,
                gradeId: isValidated.gradeId
              } :
              data
            ))

            props.updateParent()
            isChanged.current = false
            closeModal()
            //alert('Updated')

          },
          (error) => {

            addToServer()
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editStatusOffline', JSON.stringify(error), props.user.username, props.user.device)

          }
        )

      } else {

        addToServer()

      }

    }

  }

  const deleteStatus = () => {

    const addToServer = () => {

      fetch('/api/deleteStatus', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: isValidated.id
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))

          props.updateParent()
          setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
          isChanged.current = false
          closeModal()
          //alert('Deleted.')

        },
        (error) => {

          alert('Error: could not delete Status. Contact and admin.')
          console.log('Error: deleteStatus --> ' + error)
        }
      )

    }

    if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

      if (props.user.bufferData) {

        addPending({
          actionId: 3,
          action: 'deleteStatus',
          table: 'Statuses',
          id: isValidated.id
        })
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            //fetchData()
            setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
            isChanged.current = false
            closeModal()
            //alert('Deleted.')

          },
          (error) => {

            addToServer()
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteStatusOffline', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      } else {

        addToServer()

      }

    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const openAdd = () => setIsModal(prevState => ({...prevState, add: true}))

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const openJob = () => setIsModal(prevState => ({...prevState, job: true}))

  const closeJob = () => setIsModal(prevState => ({...prevState, job: false}))

  const closeNuke = () => setIsModal(prevState => ({...prevState, nuke: false}))

  const toggleHistory = () => setIsModal(prevState => ({...prevState, history: isModal.history ? false : true}))

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false}))
        clearIsValidated()
        isChanged.current = false
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      clearIsValidated()
    }

  }

  const selectJob = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    if (td[1].textContent === '' || td[1].textContent === null) {
      alert('Info has not been setup. Contact a manager.')
    } else {
      setIsValidated(prevState =>
        ({...prevState,
          jobNumber: td[3].textContent,
          gradeId: td[4].textContent === 'Grading' ? 'RG' : td[4].textContent === 'Post' ? 'PG' : ''
        })
      )
      closeJob()
    }

  }

  const selectStatus = (e) => {

    let status = e.target.textContent

    setIsValidated(prevState =>
      ({...prevState,
        status: status
      })
    )

  }

  let listOfData = fetchedData.map((data, i) => {

    let status = data.status === null ? '' : data.status
    let jn = data.jobnumber === null ? '' : data.jobnumber
    let gd = data.gradeid === null ? '' : data.gradeid === 'RG' ? 'Grading' : data.gradeid === 'PG' ? 'Post' : ''
    let entryTime = data.entrytime === null ? '' : isModal.history ? formatDateTime(data.entrytime) : formatTime(data.entrytime)

    if (
      (isModal.history || (!isModal.history && formatDateYMD(data.entrytime) === formatDateYMD(new Date()))) &&
      (searchValue === '' ||
      status.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      entryTime.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0)

    ) {
      return (
        <tr key={data.id.toString()} onClick={selectRow}>
          <td style={{display: 'none'}}>{i}</td>
          <td>{status}</td>
          <td>{jn} {gd}</td>
          <td>{entryTime}</td>
        </tr>
      )
    }

  })

  let statusContent = (

    <div>

      {isModal.edit ?  <ViewLocation data={isValidated} /> : null}

      <div style={{textAlign: 'center'}}>

        <div style={{display: 'inline-block'}}>

          <div>
            <div><label>Status</label></div>
            <div>
              <select className='select' pattern=".{1,}" name='status' onInput={validate} onChange={changedData} defaultValue={isValidated.status} required>
                <option value=''></option>
                <option value='Start Day'>Start Day</option>
                <option value='Arrive Job'>Arrive Job</option>
                <option value='On Break'>On Break</option>
                <option value='Depart Job'>Depart Job</option>
                <option value='End Day'>End Day</option>
              </select>
            </div>
          </div>

          <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={openJob}>
            <Icon name='search' />
            <span>
              {
                isValidated.jobNumber !== '' && isValidated.jobNumber !== null ?
                `${isValidated.jobNumber} ${isValidated.gradeId === 'RG' ? 'Grading' : isValidated.gradeId === 'PG' ? 'Post' : ''}` :
                props.filter.jobNumber !== '' && props.filter.jobNumber !== null ?
                `${props.filter.jobNumber} ${props.filter.gradeId === 'RG' ? 'Grading' : props.filter.gradeId === 'PG' ? 'Post' : ''}` :
                'Select a Job'
              }
            </span>
          </div>

        </div>

      </div>

    </div>

  )

  let styleStatus = {
    backgroundColor: ' dodgerblue',
    color: 'white'
  }

  let normal = {
    color: 'black',
    backGroundColor: 'white'
  }

  return (
    <>
      {isModal.add || isModal.edit ? <Modal add={isModal.add ? addStatus : isModal.edit ? editStatus : null} delete={isModal.edit ? deleteStatus : null} content={statusContent} closeModal={closeModal} /> : null}
      {isModal.job ? <JobAndGrade selectJob={selectJob} closeModal={closeJob} filter={props.filter} user={props.user} /> : null}

      <div style={{display: 'flex', width: '100%', height: '100%'}}>

        <div style={{margin: 10, flex: '1 0 auto'}}>



              <div style={{textAlign: 'center'}}>

                <p><b>Choose a Job</b></p>

                <div style={{display: 'inline-block'}}>

                  <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer', borderRadius: 10, border: '2px solid dodgerblue'}} onClick={openJob}>
                    <Icon name='search' />
                    <span style={{marginRight: 10}}>
                      {
                        isValidated.jobNumber !== '' && isValidated.jobNumber !== null ?
                        `${isValidated.jobNumber} ${isValidated.gradeId === 'RG' ? 'Grading' : isValidated.gradeId === 'PG' ? 'Post' : ''}` :
                        props.filter.jobNumber !== '' && props.filter.jobNumber !== null ?
                        `${props.filter.jobNumber} ${props.filter.gradeId === 'RG' ? 'Grading' : props.filter.gradeId === 'PG' ? 'Post' : ''}` :
                        'Select a Job'
                      }
                    </span>
                  </div>

                </div>

                <p><small>*Required if Arrive or Depart</small></p>

                <br />


                <div style={{display: 'inline-block'}}>

                  <table>

                    <thead>
                      <tr>
                        <th>Choose a Status</th>
                      </tr>
                    </thead>

                    <tbody>
                    <tr><td style={isValidated.status === 'Start Day' ? styleStatus : normal} onClick={selectStatus}>Start Day</td></tr>
                    <tr><td style={isValidated.status === 'Arrive Job' ? styleStatus : normal} onClick={selectStatus}>Arrive Job</td></tr>
                    <tr><td style={isValidated.status === 'On Break' ? styleStatus : normal} onClick={selectStatus}>On Break</td></tr>
                    <tr><td style={isValidated.status === 'Depart Job' ? styleStatus : normal} onClick={selectStatus}>Depart Job</td></tr>
                    <tr><td style={isValidated.status === 'End Day' ? styleStatus : normal} onClick={selectStatus}>End Day</td></tr>
                    </tbody>

                  </table>

                </div>

                <br />
                <br />

                <div style={{display: 'inline-block'}}>
                  <CircleButton iconName='check_circle' onClick={addStatus} />
                </div>

              </div>



        </div>

      </div>

    </>
  )

  // return (
  //   <>
  //     {isModal.add || isModal.edit ? <Modal add={isModal.add ? addStatus : isModal.edit ? editStatus : null} delete={isModal.edit ? deleteStatus : null} content={statusContent} closeModal={closeModal} /> : null}
  //     {isModal.job ? <JobAndGrade selectJob={selectJob} closeModal={closeJob} filter={props.filter} user={props.user} /> : null}
  //
  //     <div style={{display: 'flex', width: '100%', height: '100%'}}>
  //
  //       <div style={{margin: 10, flex: '1 0 auto'}}>
  //
  //         <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
  //
  //           <div>
  //             {props.user.device === 'desktop' ? <Icon name='add_circle' onClick={openAdd} /> : <AddButton onClick={openAdd} />}
  //             <Icon name='refresh' onClick={fetchData} />
  //             <Icon name='history' color={isModal.history ? 'dodgerblue' : 'gray'} onClick={toggleHistory} />
  //           </div>
  //
  //           <SearchBar search={search} clearSearch={clearSearch} />
  //
  //           {fetchedData.length > 0 ?
  //
  //             <div style={{margin: 10, flex: '1', overflow: 'auto'}}>
  //
  //               <table>
  //
  //                 <thead>
  //                   <tr>
  //                     <th>Status</th>
  //                     <th>JN</th>
  //                     <th>Time</th>
  //                   </tr>
  //                 </thead>
  //
  //                 <tbody>
  //                   {listOfData}
  //                 </tbody>
  //
  //               </table>
  //
  //             </div> :
  //             <p style={{margin: 10}}>No statuses added.</p>
  //           }
  //
  //         </div>
  //
  //       </div>
  //
  //     </div>
  //
  //   </>
  // )

}

export default Status
