import React from 'react'

const IconBig = (props) => {

  const style = {

    border: props.border != undefined ? props.border : 'none',
    color: props.color != undefined ? props.color : 'dodgerblue'

  }

  return (
    <button className='iconBig' style={style} disabled>
      <i
        style={{fontSize: props.fontSize != undefined ? props.fontSize : 50}}
        className={props.class === 'colorRainbow' ? 'material-icons colorRainbow' : props.iconClass != undefined ? props.iconClass : 'material-icons'}
        onClick={props.onClick}
        data-id={props.id}
      >
        {props.name}
      </i>
    </button>
  )

}

export default IconBig
