import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import Job from 'components/menu/Job'
import User from 'components/menu/User'
import ViewLocation from 'components/utils/ViewLocation'

import { formatDateYMD, formatDateTime, getLocation, replaceStr, filterData, addActivity } from 'scripts/common'

const Info = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [users, setUsers] = useState([])
  const [isModal, setIsModal] = useState({
    job: false,
    add: false,
    edit: false
  })
  const isChanged = useRef(false)
  const [searchValue, setSearchValue] = useState('')

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    jobNumber: '',
    gradeId: '',
    project: '',
    pm: '',
    pe: '',
    pg: '',
    sup1: '',
    sup1phone: '',
    sup1email: '',
    sup2: '',
    sup2phone: '',
    sup2email: '',
    notes: ''
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    jobNumber: '',
    gradeId: '',
    project: '',
    pm: '',
    pe: '',
    pg: '',
    sup1: '',
    sup1phone: '',
    sup1email: '',
    sup2: '',
    sup2phone: '',
    sup2email: '',
    notes: ''
  })

  const fetchData = () => {

    fetch('/api/selectManageInfo', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
      // ,
      // body: JSON.stringify({
      //   jobNumber: props.filter.jobNumber,
      //   gradeId: props.filter.gradeId
      // })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)

        addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', 'Info', props.user.username)

        setFetchedData(result)
      },
      (error) => {
        console.log('Error: selectManageInfo --> ' + error)
      }
    )

    fetch('/api/selectUsersForInfo', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
      // ,
      // body: JSON.stringify({
      //   jobNumber: props.filter.jobNumber,
      //   gradeId: props.filter.gradeId
      // })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setUsers(result)
      },
      (error) => {
        console.log('Error: selectUsersForInfo --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value
    let exists

    if (name === 'gradeId' && (isValidated.jobNumber !== null && isValidated.jobNumber !== '')) {

      exists = fetchedData.find(data => data.gradeid === value && data.jobnumber === isValidated.jobNumber)

      if (exists === undefined) {

        setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))

      } else {

        document.getElementsByName("gradeId")[0].value = ''
        alert(`Project info for ${isValidated.jobNumber} ${value === 'PG' ? 'Post' : 'Grading'} already exists.`)

      }

    } else {

      setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))

    }

  }

  const selectRow = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      //console.log(`data: ${JSON.stringify(fetchedData[i])}`)

      setIsValidated(prevState => ({...prevState,
        entryby: fetchedData[i].entryby,
        entrytime: fetchedData[i].entrytime,
        entrylat: fetchedData[i].entrylat,
        entrylng: fetchedData[i].entrylng,
        entrydevice: fetchedData[i].entrydevice,
        modby: fetchedData[i].modby,
        modtime: fetchedData[i].modtime,
        modlat: fetchedData[i].modlat,
        modlng: fetchedData[i].modlng,
        moddevice: fetchedData[i].moddevice,
        id: fetchedData[i].id,
        jobNumber: fetchedData[i].jobnumber,
        gradeId: fetchedData[i].gradeid,
        project: fetchedData[i].project,
        pm: fetchedData[i].pm,
        pe: fetchedData[i].pe,
        pg: fetchedData[i].pg,
        sup1: fetchedData[i].sup1,
        sup1phone: fetchedData[i].sup1phone,
        sup1email: fetchedData[i].sup1email,
        sup2: fetchedData[i].sup2,
        sup2phone: fetchedData[i].sup2phone,
        sup2email: fetchedData[i].sup2email,
        notes: fetchedData[i].notes
      }))
      openEdit()

    }

  }

  const changedData = () => isChanged.current = true

  const addInfo = () => {

    if (props.user.info < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')
    } else if (isValidated.jobNumber === null || isValidated.jobNumber ==='') {
        alert("Please select a job number.")
    } else if (isValidated.project === null || isValidated.project ==='') {
        alert("Please provide a project name.")
    } else if (isValidated.pm === null || isValidated.pm ==='') {
        alert("Please provide a manager.")
    } else if (isValidated.pe === null || isValidated.pe ==='') {
        alert("Please provide an engineer.")
    } else if (isValidated.pg === null || isValidated.pg ==='') {
        alert("Please provide a geologist.")
    // } else if (isValidated.sup1 === null || isValidated.sup1 ==='') {
    //     alert("Please provide a superintendent.")
    // } else if (isValidated.sup1phone === null || isValidated.sup1phone ==='') {
    //     alert("Please provide a superintendent phone.")
    // } else if (isValidated.sup1email === null || isValidated.sup1email ==='') {
    //     alert("Please provide a superintendent email.")
    } else {

      getLocation(function(latlng){

        fetch('/api/addInfo', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            jobNumber: isValidated.jobNumber,
            gradeId: isValidated.gradeId,
            project: isValidated.project,
            pm: isValidated.pm,
            pe: isValidated.pe,
            pg: isValidated.pg,
            sup1: isValidated.sup1,
            sup1phone: isValidated.sup1phone,
            sup1email: isValidated.sup1email,
            sup2: isValidated.sup2,
            sup2phone: isValidated.sup2phone,
            sup2email: isValidated.sup2email,
            notes: replaceStr(isValidated.notes)
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'add', 'Info', props.user.username)

            fetchData() // i need the id if edited
            isChanged.current = false
            closeModal()

          },
          (error) => {

            alert('Error: could not add info. Contact and admin.')
            console.log('Error: addInfo --> ' + error)
          }
        )

      })

    }

  }

  const editInfo = () => {

    if (props.user.info < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')
    } else if (isValidated.jobNumber === null || isValidated.jobNumber ==='') {
        alert("Please select a job number.")
    } else if (isValidated.gradeId === null || isValidated.gradeId ==='') {
        alert("Please select a grading phase.")
    } else if (isValidated.project === null || isValidated.project ==='') {
        alert("Please provide a project name.")
    } else if (isValidated.pm === null || isValidated.pm ==='') {
        alert("Please provide a manager.")
    } else if (isValidated.pe === null || isValidated.pe ==='') {
        alert("Please provide an engineer.")
    } else if (isValidated.pg === null || isValidated.pg ==='') {
        alert("Please provide a geologist.")
    // } else if (isValidated.sup1 === null || isValidated.sup1 ==='') {
    //     alert("Please provide a superintendent.")
    // } else if (isValidated.sup1phone === null || isValidated.sup1phone ==='') {
    //     alert("Please provide a superintendent phone.")
    // } else if (isValidated.sup1email === null || isValidated.sup1email ==='') {
    //     alert("Please provide a superintendent email.")
    } else {

      getLocation(function(latlng){

        fetch('/api/editInfo', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            id: isValidated.id,
            jobNumber: isValidated.jobNumber,
            gradeId: isValidated.gradeId,
            project: isValidated.project,
            pm: isValidated.pm,
            pe: isValidated.pe,
            pg: isValidated.pg,
            sup1: isValidated.sup1,
            sup1phone: isValidated.sup1phone,
            sup1email: isValidated.sup1email,
            sup2: isValidated.sup2,
            sup2phone: isValidated.sup2phone,
            sup2email: isValidated.sup2email,
            notes: replaceStr(isValidated.notes)
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data => isValidated.id === data.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                jobNumber: isValidated.jobNumber,
                gradeId: isValidated.gradeId,
                project: isValidated.project,
                pm: isValidated.pm,
                pe: isValidated.pe,
                pg: isValidated.pg,
                sup1: isValidated.sup1,
                sup1phone: isValidated.sup1phone,
                sup1email: isValidated.sup1email,
                sup2: isValidated.sup2,
                sup2phone: isValidated.sup2phone,
                sup2email: isValidated.sup2email,
                notes: isValidated.notes
              } : data
            ))

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'edit', 'Info', props.user.username)

            isChanged.current = false
            closeModal()

          },
          (error) => {

            alert('Error: could not edit Info. Contact and admin.')
            console.log('Error: editInfo --> ' + error)
          }
        )

      })

    }

  }

  const deleteInfo = () => {

    if (props.user.info < 3) {
      alert('You do not have the required permission. Contact an admin.')
    } else  {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

        fetch('/api/deleteInfo', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: isValidated.id
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'delete', 'Info', props.user.username)

            setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
            isChanged.current = false
            closeModal()
            //alert('Deleted.')

          },
          (error) => {

            alert('Error: could not delete info. Contact and admin.')
            console.log('Error: deleteInfo --> ' + error)
          }
        )

      }

    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const selectJob = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    setIsValidated(prevState => ({...prevState,jobNumber: td[2].textContent}))
    closeJob()

  }

  const openJob = () => setIsModal(prevState => ({...prevState, job: true}))

  const openAdd = () => {

    if (props.user.info < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else {
      setIsModal(prevState => ({...prevState, add: true}))
    }

  }

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const closeJob = () => setIsModal(prevState => ({...prevState, job: false}))

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, user: false, job: false, add: false, edit: false}))
        clearIsValidated()
      }
    } else {
      setIsModal(prevState => ({...prevState, user: false, job: false, add: false, edit: false}))
      clearIsValidated()
    }

  }

  let listOfData = fetchedData.map((data, i) => {

    let jn = data.jobnumber === null ? '' : data.jobnumber
    let gd = data.gradeid === null ? '' : data.gradeid === 'RG' ? 'Grading' : data.gradeid === 'PG' ? 'Post' : ''
    let project = data.project === null ? '' : data.project
    let client = data.client === null ? '' : data.client

    let filter = filterData(data, searchValue)

    if (filter) {
      return (
        <tr key={data.id.toString()} onClick={selectRow}>
          <td style={{display: 'none'}}>{i}</td>
          <td>{jn}</td>
          <td>{gd}</td>
          <td>{project}</td>
          <td>{client}</td>
        </tr>
      )
    }

  })

  let listOfUsers = users.map(data => {

    return <option value={data.fullname}>{data.fullname}</option>

  })

  let modalContent = (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

      <div style={{display: 'inline-block', textAlign: 'right'}}>

        <div>
          <label className='label'>JN</label>
          <input style={{width: 75}} className='input' type="text" value={isValidated.jobNumber} onClick={openJob} />
        </div>

        <div>
          <label className='label'>Grading</label>
          <select style={{width: 75}} className='select' pattern=".{1,}" name='gradeId' onInput={validate} onChange={changedData} defaultValue={isValidated.gradeId} required>
            <option value=''></option>
            <option value='RG'>Grading</option>
            <option value='PG'>Post</option>
          </select>
        </div>

        <div>
          <label className='label'>Project</label>
          <input style={{width: 300}} className='input' type="text" pattern="[a-zA-Z0-9\s]{1,}" name='project' onInput={validate} onChange={changedData} defaultValue={isValidated.project} required />
        </div>

        <div>
          <label className='label'>PM</label>
          <select className='select' pattern=".{1,}" name='pm' onInput={validate} onChange={changedData} defaultValue={isValidated.pm} required>
            <option value=''></option>
            {listOfUsers}
          </select>
        </div>

        <div>
          <label className='label'>PE</label>
          <select className='select' pattern=".{1,}" name='pe' onInput={validate} onChange={changedData} defaultValue={isValidated.pe} required>
            <option value=''></option>
            {listOfUsers}
          </select>
        </div>

        <div>
          <label className='label'>PG</label>
          <select className='select' pattern=".{1,}" name='pg' onInput={validate} onChange={changedData} defaultValue={isValidated.pg} required>
            <option value=''></option>
            {listOfUsers}
          </select>
        </div>

        <div style={{textAlign: 'center'}}><small>optional</small><h3>Supers</h3></div>

        <div>
          <label className='label'>Full Name</label>
          <input style={{width: 200}} className='input' type="text" pattern="[a-zA-Z\s]{1,}" name='sup1' onInput={validate} onChange={changedData} defaultValue={isValidated.sup1} required />
        </div>

        <div>
          <label className='label'>Email</label>
          <input style={{width: 200}} className='input' type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" placeholder='john@example.com' name='sup1email' onInput={validate} onChange={changedData} defaultValue={isValidated.sup1email} required />
        </div>

        <div>
          <label className='label'>Phone</label>
          <input style={{width: 200}} className='input' type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder='###-###-####' name='sup1phone' onInput={validate} onChange={changedData} defaultValue={isValidated.sup1phone} required />
        </div>        

        <div>
          <label className='label'>Full Name</label>
          <input style={{width: 200}} className='input' type="text" pattern="[a-zA-Z\s]{1,}" name='sup2' onInput={validate} onChange={changedData} defaultValue={isValidated.sup2} required />
        </div>

        <div>
          <label className='label'>Email</label>
          <input style={{width: 200}} className='input' type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" placeholder='john@example.com' name='sup2email' onInput={validate} onChange={changedData} defaultValue={isValidated.sup2email} required />
        </div>

        <div>
          <label className='label'>Phone</label>
          <input style={{width: 200}} className='input' type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder='###-###-####' name='sup2phone' onInput={validate} onChange={changedData} defaultValue={isValidated.sup2phone} required />
        </div>

        <div>
          <div><label className='label'>Notes</label></div>
          <textarea style={{height: 100}} className='textArea' pattern="[a-zA-Z0-9]{1,}" name='notes' onInput={validate} onChange={changedData} defaultValue={isValidated.notes} required></textarea>
        </div>

      </div>

    </div>
  )

  return (
    <>
      {isModal.add || isModal.edit ? <Modal add={isModal.add ? addInfo : isModal.edit ? editInfo : null} delete={isModal.edit ? deleteInfo : null} content={modalContent} closeModal={closeModal} isValidated={isValidated} isModal={isModal} /> : null}
      {isModal.job ? <Job selectJob={selectJob} closeModal={closeJob} /> : null}

      <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

        <div style={{flex: '1 0 auto', maxWidth: '100%'}}>

          <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

            <div style={{width: '100%'}}>

              {props.user.device === 'desktop' ? <Icon name='add_circle' onClick={openAdd} /> : <AddButton onClick={openAdd} />}
              <Icon name='refresh' onClick={fetchData} />
              
            </div>

            <SearchBar search={search} clearSearch={clearSearch} />

            {fetchedData.length > 0 ?

              <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

              <table>

                <thead>
                  <tr>
                    <th>JN</th>
                    <th></th>
                    <th>Project</th>
                    <th>Client</th>
                  </tr>
                </thead>

                <tbody>
                  {listOfData}
                </tbody>

              </table>
              </div> :
              <p style={{margin: 10}}>No info found.</p>
            }

          </div>

        </div>

      </div>

    </>
  )

}

export default Info
