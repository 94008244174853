import React, {useState, useEffect} from 'react'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'

import { getLocation, filterData } from 'scripts/common'

const Job = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [searchValue, setSearchValue] = useState('')

  const [myLatLng, setMyLatLng] = useState({lat: '', lng: ''})

  const [isModal, setIsModal] = useState({
    profile: false
  })

  const fetchData = () => {

    fetch('/api/selectMenuJob', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
      // ,
      // body: JSON.stringify({
      //   id: result[0].userid,
      //   time: formatDateTime(new Date())
      // })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedData(result)
      },
      (error) => {
        console.log('Error: selectMenuJob --> ' + error)
      }
    )

    getLocation(function(latlng){
      setMyLatLng({lat: latlng.lat, lng: latlng.lng})
    })

  }

  useEffect(() => {
    fetchData()
  }, [])

  const openProfile = () => setIsModal(prevState => ({...prevState, profile: true}))

  const closeProfile = () => setIsModal(prevState => ({...prevState, profile: false}))

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  let listOfData = fetchedData.map(data => {

    let jn = data.jobnumber === null ? '' : data.jobnumber
    let company = data.company === null ? '' : data.company
    let city = data.city === null ? '' : data.city
    let latitude = data.latitude === null ? '' : data.latitude
    let longitude = data.longitude === null ? '' : data.longitude

    let filter = filterData(data, searchValue)

    if (filter) {
      return (
        <tr onClick={props.selectJob}>
          <td style={{display: 'none'}}>{latitude}</td>
          <td style={{display: 'none'}}>{longitude}</td>
          <td>{jn}</td>
          <td>{company}</td>
          <td>{city}</td>
        </tr>
      )
    }

  })

  let style = {
    borderRadius: 5,
    margin: 10,
    padding: 5,
    cursor: 'pointer'
  }

  // <div style={{margin: 10, textAlign: 'center'}}>
  //   <span className='menuOptions' style={style} onClick={props.selectView}>Work</span>
  //   <span style={{borderRight: '1px solid gray'}}></span>
  //   <span className='menuOptions' style={style} onClick={props.selectView}>Home</span>
  //   <span style={{borderRight: '1px solid gray'}}></span>
  //   <span className='menuOptions' style={style} onClick={props.selectView}>Current Location</span>
  // </div>

  const underConstruction = () => alert('under construction')

  let content = (
    <div>

      <SearchBar search={search} clearSearch={clearSearch} />

      <div style={{textAlign: 'center', marginTop: 10}}>

        {props.mileage ?

          <>

            <div style={{display: 'inline-block', marginTop: 10}}>

              <table>

                <thead>
                  <tr>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  <tr onClick={props.selectJob}>
                    <td style={{display: 'none'}}>{33.84789303492371}</td>
                    <td style={{display: 'none'}}>{-117.86229291534426}</td>
                    <td>Work</td>
                  </tr>
                  <tr onClick={props.user.homeLat === '' || props.user.homeLng === '' ? underConstruction : props.selectJob}>
                  <td style={{display: 'none'}}>{props.user.homeLat}</td>
                  <td style={{display: 'none'}}>{props.user.homeLng}</td>
                    <td>{props.user.homeLat === '' || props.user.homeLng === '' ? 'Set Home Address' : 'Home'}</td>
                  </tr>
                  <tr onClick={props.selectJob}>
                    <td style={{display: 'none'}}>{myLatLng.lat}</td>
                    <td style={{display: 'none'}}>{myLatLng.lng}</td>
                    <td>Current Location</td>
                  </tr>
                </tbody>

              </table>

            </div><br/>

          </> : null

        }

        <div style={{display: 'inline-block', marginTop: 10}}>

          <table>

            <thead>
              <tr>
                  <th>JN</th>
                  <th>Client</th>
                  <th>City</th>
              </tr>
            </thead>

            <tbody>
              {listOfData}
            </tbody>

          </table>

        </div>

      </div>

    </div>
  )

  return <Modal content={content} closeModal={props.closeModal} />
}

export default Job
