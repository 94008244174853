import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import ViewLocation from 'components/utils/ViewLocation'

import { formatDateYMD, formatDateTime, formatDateTimeT, getLocation, replaceStr, catchError, filterData, addActivity } from 'scripts/common'

const Nukes = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [fetchedHistory, setFetchedHistory] = useState([])

  const [isModal, setIsModal] = useState({
    add: false,
    edit: false,
    editHistory: false,
    history: true
  })

  const isChanged = useRef(false)
  const [searchValue, setSearchValue] = useState('')

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    nukeId: null,
    serialNo: '',
    description: '',
    available: null,
    location: ''
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    nukeId: null,
    serialNo: '',
    description: '',
    available: null,
    location: ''
  })

  const [isValidatedHistory, setIsValidatedHistory] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    nukeId: '',
    location: '',
    checkOut: null,
    checkIn: null
  })

  const clearIsValidatedHistory = () => setIsValidatedHistory({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    nukeId: '',
    location: '',
    checkOut: null,
    checkIn: null
  })

  const fetchData = () => {

    fetch('/api/selectNukes', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))
        //setFetchedData(result)

        addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', `Nuke`, props.user.username)


        setFetchedData(result.map(data => {

          return {...data,
            nukeId: `#${data.nukeId}`,
            entrytime: data.entrytime !== null ? formatDateTime(data.entrytime) : data.entrytime,
            modtime: data.modtime !== null ? formatDateTime(data.modtime) : data.modtime
          }

        }))
      },
      (error) => {
        console.log('Error: selectNukes --> ' + error)
      }
    )

    fetch('/api/selectNukeHistory', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))
        //setFetchedHistory(result)

        setFetchedHistory(result.map(data => {

          return {...data,
            nukeId: `#${data.nukeId}`,
            checkOut: data.checkOut !== null ? formatDateTime(data.checkOut) : data.checkOut,
            checkIn: data.checkIn !== null ? formatDateTime(data.checkIn) : data.checkIn,
            entrytime: data.entrytime !== null ? formatDateTime(data.entrytime) : data.entrytime,
            modtime: data.modtime !== null ? formatDateTime(data.modtime) : data.modtime
          }

        }))
      },
      (error) => {
        console.log('Error: selectNukeHistory --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const validateHistory = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    setIsValidatedHistory(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const selectRow = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      setIsValidated(prevState => ({...prevState,
        entryby: fetchedData[i].entryby,
        entrytime: fetchedData[i].entrytime,
        entrylat: fetchedData[i].entrylat,
        entrylng: fetchedData[i].entrylng,
        entrydevice: fetchedData[i].entrydevice,
        modby: fetchedData[i].modby,
        modtime: fetchedData[i].modtime,
        modlat: fetchedData[i].modlat,
        modlng: fetchedData[i].modlng,
        moddevice: fetchedData[i].moddevice,
        id: fetchedData[i].id,
        nukeId: fetchedData[i].nukeId,
        serialNo: fetchedData[i].serialNo,
        description: fetchedData[i].description,
        available: fetchedData[i].available,
        location: fetchedData[i].location
      }))
      openEdit()

    }

  }

  const selectRowHistory = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      setIsValidatedHistory(prevState => ({...prevState,
        entryby: fetchedHistory[i].entryby,
        entrytime: fetchedHistory[i].entrytime,
        entrylat: fetchedHistory[i].entrylat,
        entrylng: fetchedHistory[i].entrylng,
        entrydevice: fetchedHistory[i].entrydevice,
        modby: fetchedHistory[i].modby,
        modtime: fetchedHistory[i].modtime,
        modlat: fetchedHistory[i].modlat,
        modlng: fetchedHistory[i].modlng,
        moddevice: fetchedHistory[i].moddevice,
        id: fetchedHistory[i].id,
        nukeId: fetchedHistory[i].nukeId,
        location: fetchedHistory[i].location,
        checkIn: fetchedHistory[i].checkIn,
        checkOut: fetchedHistory[i].checkOut
      }))
      openEditHistory()

    }

  }

  const changedData = () => isChanged.current = true

  const addNuke = () => {

    if (props.user.nuke < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')
    } else if (isValidated.nukeId === null || isValidated.nukeId ==='') {
        alert("Please provide an ID")
    } else if (isValidated.serialNo === null || isValidated.serialNo ==='') {
        alert("Please provide a serial no.")
    } else if (isValidated.available === null || isValidated.available ==='') {
        alert("Please specify if the nuke is available")
    } else if (isValidated.location === null || isValidated.location ==='') {
        alert("Please specify a location")
    } else {

      getLocation(function(latlng){

        fetch('/api/addNuke', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            nukeId: isValidated.nukeId,
            serialNo: isValidated.serialNo,
            description: replaceStr(isValidated.description),
            available: isValidated.available,
            location: isValidated.location
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'add', `Nuke ${isValidatedHistory.nukeId}`, props.user.username)

            fetchData() // i need the id if edited
            isChanged.current = false
            closeModal()
            //alert('Added.')

          },
          (error) => {

            alert('Error: could not add Nuke. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addNuke', JSON.stringify(error), props.user.username, props.user.device)

          }
        )

      })

    }

  }

  const editNuke = () => {

    if (props.user.nuke < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')
    } else if (isValidated.nukeId === null || isValidated.nukeId ==='') {
        alert("Please provide an ID")
    } else if (isValidated.serialNo === null || isValidated.serialNo ==='') {
        alert("Please provide a serial no.")
    } else if (isValidated.available === null || isValidated.available ==='') {
        alert("Please specify if the nuke is available")
    } else if (isValidated.location === null || isValidated.location ==='') {
        alert("Please specify a location")
    } else {

      getLocation(function(latlng){

        fetch('/api/editNuke', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            id: isValidated.id,
            device: props.user.device,
            id: isValidated.id,
            nukeId: isValidated.nukeId,
            serialNo: isValidated.serialNo,
            description: replaceStr(isValidated.description),
            available: isValidated.available,
            location: isValidated.location
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                nukeId: isValidated.nukeId,
                serialNo: isValidated.serialNo,
                description: isValidated.description,
                available: isValidated.available,
                location: isValidated.location
              } :
              data
            ))

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'edit', `Nuke ${isValidatedHistory.nukeId}`, props.user.username)


            isChanged.current = false
            closeModal()
            //alert('Updated')

          },
          (error) => {

            alert('Error: could not edit Nuke. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editNuke', JSON.stringify(error), props.user.username, props.user.device)

          }
        )

      })

    }

  }

  const deleteNuke = () => {

    if (props.user.nuke < 3) {
      alert('You do not have the required permission. Contact an admin.')
    } else {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

        fetch('/api/deleteNuke', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: isValidated.id
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'delete', 'Nuke', props.user.username)

            //fetchData()
            setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
            isChanged.current = false
            closeModal()
            //alert('Deleted.')

          },
          (error) => {

            alert('Error: could not delete Nuke. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteNuke', JSON.stringify(error), props.user.username, props.user.device)

          }
        )

      }

    }

  }

  const editNukeHistory = () => {

    if (props.user.nuke < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')
    } else if (isValidatedHistory.nukeId === null || isValidatedHistory.nukeId ==='') {
        alert("Please provide an ID")
    } else if (isValidatedHistory.location === null || isValidatedHistory.location ==='') {
        alert("Please specify a location")
    } else if (isValidatedHistory.checkIn === null || isValidatedHistory.checkIn ==='') {
        alert("Please specify a check-in date and time")
    } else {

      getLocation(function(latlng){

        fetch('/api/editNukeHistory', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            id: isValidatedHistory.id,
            nukeId: isValidatedHistory.nukeId,
            location: isValidatedHistory.location,
            checkOut: formatDateTime(isValidatedHistory.checkOut), // if updated, ensure correct saved format
            checkIn: formatDateTime(isValidatedHistory.checkIn)
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedHistory(fetchedHistory.map(data =>
              data.id === isValidatedHistory.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                nukeId: isValidatedHistory.nukeId,
                location: isValidatedHistory.location,
                checkOut: formatDateTime(isValidatedHistory.checkOut), // if updated, ensure correct saved format
                checkIn: formatDateTime(isValidatedHistory.checkIn)
              } :
              data
            ))

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'edit', `Nuke ${isValidatedHistory.nukeId} history`, props.user.username)

            isChanged.current = false
            closeModal()
            //alert('Updated')

          },
          (error) => {

            alert('Error: could not edit Nuke History. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editNukeHistory', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

  }

  const deleteNukeHistory = () => {

    if (props.user.nuke < 3) {
      alert('You do not have the required permission. Contact an admin.')
    } else {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

        fetch('/api/deleteNukeHistory', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: isValidatedHistory.id
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'delete', `Nuke ${isValidatedHistory.nukeId} history`, props.user.username)


            //fetchData()
            setFetchedHistory(fetchedHistory.filter(data => data.id !== isValidatedHistory.id))
            isChanged.current = false
            closeModal()
            //alert('Deleted.')

          },
          (error) => {

            alert('Error: could not delete Nuke History. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteNukeHistory', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      }

    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const openAdd = () => {

    if (props.user.nuke < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else {
      setIsModal(prevState => ({...prevState, add: true}))
    }

  }

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const openEditHistory = () => setIsModal(prevState => ({...prevState, editHistory: true}))

  const toggleHistory = () => setIsModal(prevState => ({...prevState, history: isModal.history ? false : true}))

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false, editHistory: false}))
        clearIsValidated()
        clearIsValidatedHistory()
        isChanged.current = false
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false, editHistory: false}))
      clearIsValidated()
      clearIsValidatedHistory()
    }

  }

  let listOfData = fetchedData.map((data, i) => {

    let nukeId = data.nukeId === null ? '' : data.nukeId
    let serialNo = data.serialNo === null ? '' : data.serialNo
    let description = data.description === null ? '' : data.description
    let available = data.available === 1 ? 'Yes' : data.available === 0 ? 'No' : ''
    let location = data.location === null ? '' : data.location

    let filter = filterData(data, searchValue)

    if (filter) {
      return (
        <tr key={data.id.toString()} onClick={selectRow}>
          <td style={{display: 'none'}}>{i}</td>
          <td>{nukeId}</td>
          <td>{serialNo}</td>
          <td style={{backgroundColor: available === 'Yes' ? 'dodgerblue': 'tomato'}}>{available}</td>
          <td>{location}</td>
          <td>{description}</td>
        </tr>
      )
    }

  })

  let listOfHistory = fetchedHistory.map((data, i) => {

    let nukeId = data.nukeId === null ? '' : data.nukeId
    let location = data.location !== null && data.location !== '' ? data.location : ''
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : ''
    let entryTime = data.entrytime === null ? '' : data.entrytime
    let modBy = data.modby !== null && data.modby !== '' ? data.modby : ''
    let checkOut = data.checkOut === null ? '' : data.checkOut
    let checkIn = data.checkIn === null ? '' : data.checkIn

    let color = modBy !== '' && entryBy !== modBy ? 'tomato' : ''

    let filter = filterData(data, searchValue)

    if (filter) {
      return (
        <tr key={data.id.toString()} onClick={selectRowHistory}>
          <td style={{display: 'none'}}>{i}</td>
          <td style={{backgroundColor: color}}>{nukeId}</td>
          <td style={{backgroundColor: color}}>{location}</td>
          <td style={{backgroundColor: color}}>{entryBy}</td>
          <td style={{backgroundColor: color}}>{checkOut !== '' ? checkOut : entryTime}</td>
          <td style={{backgroundColor: color}}>{checkIn}</td>
        </tr>
      )
    }

  })

  let listOfNukes = fetchedData.map(data => <option value={data.nukeId}>{data.nukeId}</option>)

  let modalContent = (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

      {isModal.edit ?  <ViewLocation data={isValidated} /> : null}

      <div style={{display: 'inline-block'}}>

        <div>
          <label>Nuke Id</label><br/>
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}" name='nukeId' onInput={validate} onChange={changedData} defaultValue={isValidated.nukeId} required />
        </div>

        <div>
          <label>Serial No</label><br/>
          <input style={{width: 75}} className='input' type="text" pattern="[a-zA-Z0-9]{1,}" name='serialNo' onInput={validate} onChange={changedData} defaultValue={isValidated.serialNo} required />
        </div>

        <div>
          <label>Available?</label><br/>
          <select style={{width: 75}} className='select' pattern=".{1,}" name='available' onInput={validate} onChange={changedData} defaultValue={isValidated.available} required>
            <option value=""></option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>

        <div>
          <label>Location</label><br/>
          <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='location' onInput={validate} onChange={changedData} defaultValue={isValidated.location} required></textarea>
        </div>

        <div>
          <label>Notes</label><br/>
          <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='description' onInput={validate} onChange={changedData} defaultValue={isValidated.description} placeholder='Optional' required></textarea>
        </div>

      </div>

    </div>
  )

  let modalHistory = (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

      {isModal.editHistory ?  <ViewLocation data={isValidatedHistory} /> : null}

      <div style={{display: 'inline-block'}}>

        <div>
          <label>Nuke Id</label><br/>
          <select style={{width: 75}} className='select' pattern=".{1,}" name='available' onInput={validateHistory} onChange={changedData} defaultValue={isValidatedHistory.nukeId} required>
            <option value=""></option>
            {listOfNukes}
          </select>
        </div>

        <div>
          <label>Location</label><br/>
          <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='location' onInput={validateHistory} onChange={changedData} defaultValue={isValidatedHistory.location} required></textarea>
        </div>

        <div>
          <label>User</label><br/>
          <input className='input' type="text" defaultValue={isValidatedHistory.entryby} disabled />
        </div>

        <div>
          <label>Check Out</label><br/>
          <input
            className='input'
            type="datetime-local"
            pattern="{1,}"
            name='checkOut'
            onInput={validateHistory}
            onChange={changedData}
            defaultValue={isValidatedHistory.checkOut === null ? formatDateTimeT(isValidatedHistory.entrytime) : formatDateTimeT(isValidatedHistory.checkOut)}
            required
          />
        </div>

        <div>
          <label>Check In</label><br/>
          <input
            className='input'
            type="datetime-local"
            pattern="{1,}"
            name='checkIn'
            onInput={validateHistory}
            onChange={changedData}
            defaultValue={formatDateTimeT(isValidatedHistory.checkIn)}
            required
          />
        </div>

      </div>

    </div>
  )

  return (
    <>
      {isModal.add || isModal.edit ?
        <Modal
          add={isModal.add ? addNuke : isModal.edit ? editNuke : null}
          delete={isModal.edit ? deleteNuke : null}
          content={modalContent}
          closeModal={closeModal}
        /> : null
      }

      {isModal.editHistory ?
        <Modal
          add={editNukeHistory}
          delete={deleteNukeHistory}
          content={modalHistory}
          closeModal={closeModal}
        /> : null
      }

      <div style={{display: 'flex', width: '100%', height: '100%'}}>

        <div style={{flex: '1 0 auto', maxWidth: '100%'}}>

          <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

            <div style={{width: '100%'}}>

              {props.user.device === 'desktop' ? <Icon name='add_circle' onClick={openAdd} /> : <AddButton onClick={openAdd} />}
              <Icon name='refresh' onClick={fetchData} />
              <Icon name='history' color={isModal.history ? 'dodgerblue' : 'gray'} onClick={toggleHistory} />

            </div>

            <SearchBar search={search} clearSearch={clearSearch} />

            {fetchedData.length > 0 ?

              <div style={{flex: '1', overflow: 'auto'}}>

                <table>

                  <thead>
                    <tr>
                      <th>Nuke Id</th>
                      <th>Serial No</th>
                      <th>Available</th>
                      <th>Location</th>
                      <th>Notes</th>
                    </tr>
                  </thead>

                  <tbody>
                    {listOfData}
                  </tbody>

                </table>

              </div> :
              <p style={{margin: 10}}>No nukes added.</p>
            }

          </div>

        </div>

        {isModal.history ?

          <div style={{flex: '1 0 auto', maxWidth: '100%'}}>

            <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

              <h3>History</h3>
              <div style={{display: 'flex', alignItems: 'center', backgroundColor: 'white', borderRadius: 5}}>
                <Icon name='circle' color='tomato' /><span style={{marginRight: 10}}>Edited</span>
              </div>

              {fetchedData.length > 0 ?

                <div style={{flex: '1', overflow: 'auto'}}>

                  <table>

                    <thead>
                      <tr>
                        <th>Nuke Id</th>
                        <th>Location</th>
                        <th>User</th>
                        <th>Check Out</th>
                        <th>Check In</th>
                      </tr>
                    </thead>

                    <tbody>
                      {listOfHistory}
                    </tbody>

                  </table>

                </div> :
                <p style={{margin: 10}}>No history found.</p>
              }

            </div>

          </div> : null

        }

      </div>

    </>
  )

}

export default Nukes
