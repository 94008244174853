import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import SearchBar from 'components/utils/SearchBar'

import { formatDateYMD, formatDateTime, getLocation, catchError, filterData, addActivity } from 'scripts/common'

const MyLab = (props) => {

  const [fetchedLab, setFetchedLab] = useState([])
  const [fetchedMaterial, setFetchedMaterial] = useState([])

  const [isModal, setIsModal] = useState({
    loading: true,
    sort: false, // false is by date, true is tech, date
    view: false // false is Lab and true is material
  })

  const [searchValue, setSearchValue] = useState('')

  const fileArray = useRef([])

  const fetchData = () => {

    fetch('/api/selectMyLab', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter,
        user: props.user.username
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)

        addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', 'My Lab', props.user.username)

        setFetchedLab(result)
        setIsModal(prevState => ({...prevState, loading: false}))
      },
      (error) => {
        catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectMyLab', JSON.stringify(error), props.user.username, props.user.device)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [props.filter])

  const selectRow = (e) => {

    // let tr = e.target.parentNode
    // let td = tr.getElementsByTagName('td')
    // let i = td[0].textContent

    // if (i === '' || i === null) {
    //   alert('Error: data i not found. Contact an admin.')
    // } else {

    //   window.open(`Lab/daily${fetchedLab[i].id}.pdf`)

    // }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  //fileArray.current = [] // clear before pushing

  let listOfLab = fetchedLab.map((data, i) => {

    let jn = data.jobnumber === null ? '' : data.jobnumber
    let gd = data.gradeid === null ? '' : data.gradeid === 'RG' ? 'Grading' : data.gradeid === 'PG' ? 'Post' : ''
    let sampleNo = data.sampleno === null ? '' : data.sampleno
    let location = data.location === null ? '' : data.location
    let represents = data.represents === null ? '' : data.represents
    let purpose = data.purpose === null ? '' : data.purpose
    let sampleType = data.sampletype === null ? '' : data.sampletype
    let description = data.description === null ? '' : data.description
    let color = data.color === null ? '' : data.color    
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : '?'
    let entryTime = data.entrytime === null ? '' : formatDateTime(data.entrytime)
    let status = data.status === null ? '' : data.status
    let sampleStatus = data.sampleStatus === null ? '' : data.sampleStatus

    //  || entryTime.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0

    let filter = filterData(data, searchValue)

    if (filter) {

      //fileArray.current.push(`daily${data.id}.pdf`)

      return (
        <tr key={i} onClick={selectRow}>
            <td style={{display: 'none'}}>{i}</td>
            <td>{entryTime}</td>
            {props.filter.jobNumber === '' ? <td>{jn}</td> : null}
            <td>{status}</td>
            <td>{entryBy}</td>
            <td>{sampleNo}</td>
            <td className='wrapText'>{location}</td>
            <td className='wrapText'>{represents}</td>
            <td className='wrapText'>{purpose}</td>
            <td>{sampleType}</td>
        </tr>
      )
      
    }

  })

  let styleBtn = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 10,
    border: '2px solid dodgerblue',
    margin: 10,
    width: 100
  }

  return (
    <>
      {!isModal.loading ?
        <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

          <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

            <SearchBar search={search} clearSearch={clearSearch} />

            {fetchedLab.length > 0 ?

              <>

                <small style={{margin: 10}}>{fetchedLab.length} Total Records</small>

                <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                  <table id="toExcel">

                    <thead>
                      <tr>
                        <th>Date</th>
                        {props.filter.jobNumber === '' ? <th>JN</th> : null}
                        <th>Status</th>
                        <th>By</th>
                        <th>SN</th>
                        <th>Location</th>
                        <th>Represents</th>
                        <th>Purpose</th>
                        <th>Type</th>
                      </tr>
                    </thead>

                    <tbody>
                      {listOfLab}
                    </tbody>

                  </table>

                </div>

              </> : null

            }

          </div>

        </div> : <p style={{margin: 10}}>Loading...</p>
      }
    </>
  )

}

export default MyLab
