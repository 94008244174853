import React, { useState, useEffect} from 'react'
import Icon from 'components/utils/Icon'
import SearchBar from 'components/utils/SearchBar'

import { filterData } from 'scripts/common'

const Permissions = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [searchValue, setSearchValue] = useState('')

  const fetchData = () => {

    fetch('/api/fetchPermissions', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {

        //console.log(`fetchedData: ${JSON.stringify(result)}`)

        setFetchedData(result)
      },
      (error) => {
        console.log('error: ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const Select = (props) => {

    let v = parseInt(props.value) || 0

    let style = {color: 'white', backgroundColor: v === 0 ? 'tomato' : v === 1 ? 'orange' : v === 2 ? 'gray' : v === 3 ? 'mediumseagreen' : 'dodgerblue'}

    return (
      <select className="select" style={style} value={props.value} name={props.name} data-id={props.id} onChange={props.onChange}>
        <option value='0' defaultValue>No Access</option>
        <option value='1'>View</option>
        <option value='2'>Tech - Add/Edit</option>
        <option value='3'>Tech - Full</option>
        <option value='4'>Manager - Add/Edit</option>
        <option value='5'>Manager - Full</option>
      </select>
    )
  }

  const SelectManage = (props) => {

    let v = parseInt(props.value) || 0

    let style = {color: 'white', backgroundColor: v === 0 ? 'tomato' : v === 1 ? 'orange' : v === 2 ? 'gray' : v === 3 ? 'mediumseagreen' : 'dodgerblue'}

    //console.log(`style ${JSON.stringify(style)}`)

    return (
      <select className="select" style={style} value={props.value} name={props.name} data-id={props.id} onChange={props.onChange}>
        <option value='0' defaultValue>No Access</option>
        <option value='1'>View</option>
        <option value='2'>Add/Edit</option>
        <option value='3'>Full</option>
      </select>
    )
  }

  const SelectView = (props) => {

    let v = parseInt(props.value) || 0

    let style = {color: 'white', backgroundColor: v === 0 ? 'tomato' : v === 1 ? 'orange' : v === 2 ? 'gray' : v === 3 ? 'mediumseagreen' : 'dodgerblue'}

    return (
      <select className="select" style={style} value={props.value} name={props.name} data-id={props.id} onChange={props.onChange}>
        <option value='0' defaultValue>No Access</option>
        <option value='1'>View</option>
      </select>
    )
  }

  const insertPermission = (event) => {
    let el = event.target
    let id = parseInt(el.getAttribute('data-id'))
    let field = el.getAttribute('name')
    let value = el.value

    fetch('/api/insertPermission', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: id, field: field, value: value})
    })
    .then(res=>res.json())
    .then(
      (result) => {
        fetchData()
      },
      (error) => {
        console.log('error: ' + error)
      }
    )
  }

  const updatePermission = (event) => {
    let el = event.target
    let id = parseInt(el.getAttribute('data-id'))
    let field = el.getAttribute('name')
    let value = el.value

    fetch('/api/updatePermission', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: id, field: field, value: value})
    })
    .then(res=>res.json())
    .then(
      (result) => {
        fetchData()
      },
      (error) => {
        console.log('error: ' + error)
      }
    )
  }

  let listOfData = fetchedData.map((data, i) => {

    let fullName = data.fullname === null ? '' : data.fullname

    let onChange = data.id === null ? insertPermission : updatePermission
    let id = data.id === null ? data.userid : data.id

    let filter = filterData(data, searchValue)

    if (filter) {

      return (
        <tr>
          <td>{fullName}</td>
          <td>{data.userlevel}</td>
          <td><Select value={data.info} name='info' id={id} onChange={onChange} /></td>
          <td><Select value={data.issue} name='issue' id={id} onChange={onChange} /></td>
          <td><Select value={data.test} name='test' id={id} onChange={onChange} /></td>
          <td><Select value={data.lab} name='lab' id={id} onChange={onChange} /></td>
          <td><Select value={data.plan} name='plan' id={id} onChange={onChange} /></td>
          <td><Select value={data.drawing} name='drawing' id={id} onChange={onChange} /></td>
          <td><Select value={data.daily} name='daily' id={id} onChange={onChange} /></td>
          <td><Select value={data.document} name='document' id={id} onChange={onChange} /></td>
          <td><Select value={data.concrete} name='concrete' id={id} onChange={onChange} /></td>
          <td><SelectView value={data.manage} name='manage' id={id} onChange={onChange} /></td>
          <td><SelectView value={data.analytics} name='analytics' id={id} onChange={onChange} /></td>
          <td><SelectView value={data.permission} name='permission' id={id} onChange={onChange} /></td>
          <td><SelectManage value={data.job} name='job' id={id} onChange={onChange} /></td>
          <td><SelectManage value={data.max} name='max' id={id} onChange={onChange} /></td>
          <td><SelectView value={data.billing} name='billing' id={id} onChange={onChange} /></td>
          <td><SelectManage value={data.prefix} name='prefix' id={id} onChange={onChange} /></td>
          <td><SelectManage value={data.preset} name='preset' id={id} onChange={onChange} /></td>
          <td><SelectManage value={data.presetType} name='presetType' id={id} onChange={onChange} /></td>
          <td><SelectManage value={data.task} name='task' id={id} onChange={onChange} /></td>
          <td><SelectManage value={data.user} name='user' id={id} onChange={onChange} /></td>
          <td><SelectManage value={data.nuke} name='nuke' id={id} onChange={onChange} /></td>
          <td><SelectManage value={data.dailyDescription} name='dailyDescription' id={id} onChange={onChange} /></td>
          <td><SelectManage value={data.equipDescription} name='equipDescription' id={id} onChange={onChange} /></td>
        </tr>
      )

    }

  })

  return (
    <div style={{display: 'flex', width: '100%', height: '100%'}}>

      <div style={{margin: 10, flex: '1 0 auto'}}>

        <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

          <div style={{display: 'flex', alignItems: 'center', backgroundColor: 'white', borderRadius: 5}}>
            <Icon name='stop_circle' color='tomato' /><span style={{marginRight: 10}}>No Access</span>
            <Icon name='stop_circle' color='orange' /><span style={{marginRight: 10}}>View</span>
            <Icon name='stop_circle' color='gray' /><span style={{marginRight: 10}}>Add/Edit</span>
            <Icon name='stop_circle' color='mediumseagreen' /><span style={{marginRight: 10}}>Full</span>
            <Icon name='stop_circle' color='dodgerblue' /><span style={{marginRight: 10}}>Manage+</span>
          </div>

          <SearchBar search={search} clearSearch={clearSearch} />

          {fetchedData.length > 0 ?

            <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

              <table>

                <thead>
                  <tr>
                    <th></th>
                    <th>Role</th>
                    <th>Info</th>
                    <th>Issues</th>
                    <th>Tests</th>
                    <th>Lab</th>
                    <th>Plans</th>
                    <th>Drawings</th>
                    <th>Dailies</th>
                    <th>Documents</th>
                    <th>Concrete</th>
                    <th>Manage</th>
                    <th>Analytics</th>
                    <th>Permissions</th>
                    <th>Jobs</th>
                    <th>Maxes</th>
                    <th>Billing</th>
                    <th>Prefixes</th>
                    <th>Presets</th>
                    <th>Preset Types</th>
                    <th>Tasks</th>
                    <th>Users</th>
                    <th>Nukes</th>
                    <th>Daily Descrip</th>
                    <th>Equip Descrip</th>
                  </tr>
                </thead>

                <tbody>
                  {listOfData}
                </tbody>

              </table>

            </div> :
            <p style={{margin: 10}}>No users found.</p>
          }

        </div>

      </div>

    </div>
  )
}

export default Permissions
