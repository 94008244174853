import React, {useState, useEffect} from 'react'

import { catchError, addActivity } from 'scripts/common'

import { selectData } from 'scripts/offline'

const Info = (props) => {

  const [fetchedData, setFetchedData] = useState([])

  const fetchData = () => {

    if (props.user.offline && props.user.offlineJob === props.filter.jobNumber) {

      selectData('Info').then(res => setFetchedData(res))

    } else {

      fetch('/api/selectInfo', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: props.filter
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)

          addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'view', '', props.user.username)

          setFetchedData(result)
        },
        (error) => {
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectInfo', JSON.stringify(error), props.user.username, props.user.device)
        }
      )

    }

  }

  useEffect(() => {
    fetchData()
  }, [props.filter.jobNumber, props.filter.gradeId])

  let styleList = {
    margin: 10
  }

  let content = fetchedData.length > 0 ?
  <ul>
    <li style={styleList}><b>Project</b> - {fetchedData[0].project}</li>
    <li style={styleList}><b>Client</b> - {fetchedData[0].company}</li>
    <li style={styleList}><b>PM</b> - {fetchedData[0].pm}</li>
    <li style={styleList}><b>PE</b> - {fetchedData[0].pe}</li>
    <li style={styleList}><b>PG</b> - {fetchedData[0].pg}</li>
    <li style={styleList}>
      <b>Super</b> - {fetchedData[0].sup1}
      <div style={styleList}><a href={'tel:' + fetchedData[0].sup1phone}>Call</a> - {fetchedData[0].sup1phone}</div>
      <div style={styleList}><a href={'mailto:' + fetchedData[0].sup1email}>Email</a> - {fetchedData[0].sup1email}</div>
    </li>

    {fetchedData[0].sup2 !== null && fetchedData[0].sup2 !== '' ?

      <li style={styleList}>
        <b>Super</b> - {fetchedData[0].sup2}
        <div style={styleList}><a href={'tel:' + fetchedData[0].sup2phone}>Call</a> - {fetchedData[0].sup2phone}</div>
        <div style={styleList}><a href={'mailto:' + fetchedData[0].sup2email}>Email</a> - {fetchedData[0].sup2email}</div>
      </li> : null
    }



    <li><b>Notes</b>: {fetchedData[0].notes}</li>
  </ul> :
  <p>No information has been provided.</p>

  return props.filter.jobNumber !== null && props.filter.jobNumber !== '' ? content : null

}

export default Info
