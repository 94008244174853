import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import CircleButton from 'components/utils/CircleButton'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import Locate from 'components/menu/Locate'

import { formatDateYMD, formatDateTime, getLocation, catchError, replaceStr, filterData, addActivity } from 'scripts/common'

import { addPending, selectData } from 'scripts/offline';

const Concrete = (props) => {

  // const googleRef = useRef(null)
  // const mapRef = useRef(null)
  // const markerRef = useRef([])

  const [fetchedData, setFetchedData] = useState([])
  const [isModal, setIsModal] = useState({
    add: false,
    edit: false,
    locate: false,
    warning: 0,
    warningContent: '',
    loading: true,
    delivered: false
  })

  const isChanged = useRef(false)

  const [searchValue, setSearchValue] = useState('')

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    castDate: '',
    timeCast: '',
    fieldMarking: '',
    represents: '',
    location: '',
    structureType: '',
    otherStructure: '',
    reinforcementType: '',
    materialType: '',
    specimenType: '',
    sampleType: '',
    mixNo: '',
    concreteSupplier: '',
    batchPlantLocation: '',
    deliveryTicketNo: '',
    slumpMeasured: '',
    waterAdded: '',
    airContent: '',
    concreteTemp: '',
    airTemp: '',
    designStrength: '',
    designStrengthDay: '28',
    age1: '',
    age2: '',
    age3: '',
    age4: '',
    age5: '',
    age6: '',
    latitude: '',
    longitude: '',
    notes: ''
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    castDate: '',
    timeCast: '',
    fieldMarking: '',
    represents: '',
    location: '',
    structureType: '',
    otherStructure: '',
    reinforcementType: '',
    materialType: '',
    specimenType: '',
    sampleType: '',
    mixNo: '',
    concreteSupplier: '',
    batchPlantLocation: '',
    deliveryTicketNo: '',
    slumpMeasured: '',
    waterAdded: '',
    airContent: '',
    concreteTemp: '',
    airTemp: '',
    designStrength: '',
    designStrengthDay: '',
    age1: '',
    age2: '',
    age3: '',
    age4: '',
    age5: '',
    age6: '',
    latitude: '',
    longitude: '',
    notes: ''
  })

  const fetchData = () => {

    if (props.filter.offline && props.user.offline === props.filter.jobNumber) {

      selectData('Concrete').then(res => {
        setIsModal(prevState => ({...prevState, loading: false}))
      })

    } else {

      fetch('/api/selectConcrete', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: props.filter
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //setFetchedData(result)

          setFetchedData(result.map(data => {

            return (
              {...data,
                entrytime: formatDateTime(data.entrytime),
                modtime: formatDateTime(data.modtime),
                castDate: formatDateYMD(data.castDate),
                deliveredtime: formatDateTime(data.deliveredtime)
              }
            )

        }))

          setIsModal(prevState => ({...prevState, loading: false}))
        },
        (error) => {
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectConcrete', JSON.stringify(error), props.user.username, props.user.device)
        }
      )

    }

  }

  useEffect(() => {
    fetchData()
  }, [props.filter])

  // Sets the map on all markers in the array.
  // const hightlightMarker = (e) => {
  //
  //   let id = parseInt(e.target.parentNode.getAttribute('data-id'))
  //
  //   for (let i = 0; i < markerRef.current.length; i++) {
  //     if (markerRef.current[i].get("id") === id) {
  //
  //       markerRef.current[i].setIcon('http://maps.google.com/mapfiles/ms/icons/yellow-dot.png')
  //       markerRef.current[i].setAnimation(window.google.maps.Animation.BOUNCE)
  //     } else {
  //
  //       markerRef.current[i].setIcon('http://maps.google.com/mapfiles/ms/icons/blue-dot.png')
  //       markerRef.current[i].setAnimation(null)
  //       markerRef.current[i].getVisible(false)
  //     }
  //
  //     markerRef.current[i].setMap(mapRef.current);
  //   }
  // }
  //
  // const updateMap = (id, visible) => {
  //
  //   for (let i = 0; i < markerRef.current.length; i++) {
  //     if (markerRef.current[i].get("id") === id) {
  //
  //       markerRef.current[i].setVisible(visible)
  //
  //     }
  //
  //     markerRef.current[i].setMap(mapRef.current);
  //   }
  // }
  //
  // const initMap = () => {
  //
  //   let data = fetchedData.filter(data => {
  //
  //     if (data.entrylat !== '' && data.entrylat !== null && data.entrylng !== '' && data.entrylng !== null) {
  //
  //       let sampleNo = data.sampleno === null ? '' : data.sampleno
  //       let location = data.location === null ? '' : data.location
  //       let represents = data.represents === null ? '' : data.represents
  //       let purpose = data.purpose === null ? '' : data.purpose
  //       let sampleType = data.sampletype === null ? '' : data.sampletype
  //       let description = data.description === null ? '' : data.description
  //       let color = data.color === null ? '' : data.color
  //       let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : data.tech !== null ? data.tech : ''
  //       let entryTime = data.entrytime === null ? '' : data.entrytime
  //
  //       return true
  //
  //       //{testNo: testNo, lat: data.entrylat, lng: data.entrylng}
  //
  //     }
  //
  //     return false
  //
  //
  //   })
  //
  //   let google = googleRef.current
  //
  //   mapRef.current = new window.google.maps.Map(google, {
  //        mapTypeId: window.google.maps.MapTypeId.ROADMAP
  //   });
  //
  //   let infoWindow = new window.google.maps.InfoWindow;
  //
  //   let marker, i, latLng, sampleNo
  //
  //   //let i, latLng
  //
  //   let bounds = new window.google.maps.LatLngBounds();
  //
  //   //console.log(`locations: ${JSON.stringify(data)}`)
  //
  //   for (i = 0; i < data.length; i++) {
  //
  //     sampleNo = data[i].sampleno === null ? '' : data[i].sampleno
  //
  //     marker = new window.google.maps.Marker({
  //       position: new window.google.maps.LatLng(data[i].entrylat, data[i].entrylng),
  //       title: sampleNo,
  //       animation: window.google.maps.Animation.DROP,
  //       map: mapRef.current,
  //       icon: {
  //         url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
  //       },
  //       id: data[i].id,
  //       visible: filterData(data[i])
  //     });
  //
  //     marker.setMap(mapRef.current)
  //
  //     markerRef.current.push(marker)
  //
  //     latLng = new window.google.maps.LatLng(data[i].entrylat, data[i].entrylng);
  //
  //     bounds.extend(latLng);
  //
  //     window.google.maps.event.addListener(marker, 'click', function () {
  //       infoWindow.setContent('<h3>' + this.title + '</h3>');
  //       infoWindow.open(mapRef.current, this);
  //     });
  //
  //   }
  //
  //   mapRef.current.fitBounds(bounds);
  //
  //   mapRef.current.setCenter(bounds.getCenter());
  //
  // }

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'yellow'

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const selectRow = (e) => {

    let tr = e.target.nodeName === 'I' ? e.target.parentNode.parentNode.parentNode : e.target.nodeName === 'Button' ? e.target.parentNode.parentNode : e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (isModal.delivered  && (e.target.nodeName === 'I' || e.target.nodeName === 'Button')) {
      updateDelivered(e)
    } else if (!isModal.delivered  && (e.target.nodeName === 'I' || e.target.nodeName === 'Button')) {
      showDeliveredInfo(e)
    } else if (i === '' || i === null) {
      alert('Error: data id not found. Contact an admin.')
    } else {

      //check for warning => 0: can edit/delete, 1: can edit, 2: cannot edit/delete
      let today = new Date()
      let entry = new Date(fetchedData[i].entrytime)
      let timeDiff = Math.abs(entry.getTime() - today.getTime())
      let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) // 1 day

      if (props.user.concrete < 2) {

        setIsModal(prevState => ({...prevState, warning: 2, warningContent: 'Cannot Edit/Delete: You do not have required permission. Contact an Admin.'}))

      } else if ((props.user.concrete === 2 || props.user.concrete === 3) && props.user.username !== fetchedData[i].entryby) {

        setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: ${fetchedData[i].entryby} is the owner.`}))

      } else if ((props.user.concrete === 2 || props.user.concrete === 3) && props.user.username === fetchedData[i].entryby && diffDays > 1) {

        setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: Time has elasped (24 hrs). Contact a Manager`}))

      } else if (props.user.concrete === 4) {

        setIsModal(prevState => ({...prevState, warning: 1, warningContent: `Cannot Delete: You do not have required permission. Contact an Admin.`}))

      } else {

        setIsModal(prevState => ({...prevState, warning: 0, warningContent: ``}))

      }

      //console.log(`selected: ${JSON.stringify(fetchedData[i])}`)

      // setIsValidated(prevState => ({...prevState,
      //   entryby: fetchedData[i].entryby,
      //   entrytime: fetchedData[i].entrytime,
      //   entrylat: fetchedData[i].entrylat,
      //   entrylng: fetchedData[i].entrylng,
      //   entrydevice: fetchedData[i].entrydevice,
      //   modby: fetchedData[i].modby,
      //   modtime: fetchedData[i].modtime,
      //   modlat: fetchedData[i].modlat,
      //   modlng: fetchedData[i].modlng,
      //   moddevice: fetchedData[i].moddevice,
      //   id: fetchedData[i].id,
      //   castDate: fetchedData[i].,
      //   fieldMarking: fetchedData[i].,
      //   mixNo: fetchedData[i].,
      //   designStrength: fetchedData[i].,
      //   diameter: '',
      //   materialType: '',
      //   specimenType: '',
      //   sampleType: '',
      //   location: '',
      //   represents: '',
      //   concreteSupplier: '',
      //   batchPlantLocation: '',
      //   deliveryTicketNo: '',
      //   timeCast: '',
      //   slumpMeasured: '',
      //   waterAdded: '',
      //   airContent: '',
      //   concreteTemp: '',
      //   airTemp: ''
      // }))

      setIsValidated(fetchedData[i])

      openEdit()

    }

  }

  const changedData = () => isChanged.current = true

  const updateDelivered = (e) => {

    let tr = e.target.nodeName === 'I' ? e.target : e.target.childNode
    let i = parseInt(tr.getAttribute('data-i'))
    let status = (fetchedData[i].deliveredtime !== '' && fetchedData[i].deliveredtime !== null) || fetchedData[i].entrytime === '' || fetchedData[i].entrytime === null ? true : false

    const deliver = () => {

      getLocation(function(latlng){

        fetch('/api/deliverMaterial', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: fetchedData[i].id,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            status: status
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'deliver', `Cast: ${isValidated.castDate}`, props.user.username)

            fetchData() // i need the id if edited

          },
          (error) => {

            alert('Error: could not add Cconcrete. Contact and admin.')
            catchError(props.filter.jobNumber, '', props.component, 'addConcrete', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

    if (i === '' || i === null) {
      alert('Error: data id not found. Contact an admin.')
    } else if (status && window.confirm('Mark the sample as Un-Delivered?')) {
      deliver()
    } else {
      deliver()
    }

  }

  const showDeliveredInfo = (e) => {

    let tr = e.target.nodeName === 'I' ? e.target : e.target.childNode
    let i = parseInt(tr.getAttribute('data-i'))

    alert(`
    ${fetchedData[i].entrytime !== null && fetchedData[i].entrytime !== '' ?
    'Sampled by: ' + fetchedData[i].entryby + ' @ ' + fetchedData[i].entrytime + '\n' : 'Not sampled\n'}

    ${fetchedData[i].deliveredtime !== null && fetchedData[i].deliveredtime !== '' ?
    'Delivered by: ' + fetchedData[i].deliveredby + ' @ ' + fetchedData[i].deliveredtime : 'Not delivered'}
    `)
  }

  const selectLocate = (lat,lng) => {
    isChanged.current = true
    setIsValidated(prevState => ({...prevState,
      latitude: lat,
      longitude: lng
    }))

    closeLocate()
  }

  const addData = () => {

    // OFFLINE not done

    const addToServer = () => {

      getLocation(function(latlng){

        fetch('/api/addConcrete', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            jobNumber: props.filter.jobNumber,
            castDate: isValidated.castDate,
            timeCast: isValidated.timeCast,
            fieldMarking: isValidated.fieldMarking,
            represents: replaceStr(isValidated.represents),
            location: replaceStr(isValidated.location),
            structureType: isValidated.structureType,
            otherStructure: replaceStr(isValidated.otherStructure),
            reinforcementType: isValidated.reinforcementType,
            materialType: isValidated.materialType,
            specimenType: isValidated.specimenType,
            sampleType: isValidated.sampleType,
            mixNo: isValidated.mixNo,
            concreteSupplier: replaceStr(isValidated.concreteSupplier),
            batchPlantLocation: isValidated.batchPlantLocation,
            deliveryTicketNo: isValidated.deliveryTicketNo,
            slumpMeasured: isValidated.slumpMeasured,
            waterAdded: isValidated.waterAdded,
            airContent: isValidated.airContent,
            concreteTemp: isValidated.concreteTemp,
            airTemp: isValidated.airTemp,
            designStrength: isValidated.designStrength,
            designStrengthDay: isValidated.designStrengthDay,
            age1: isValidated.age1,
            age2: isValidated.age2,
            age3: isValidated.age3,
            age4: isValidated.age4,
            age5: isValidated.age5,
            age6: isValidated.age6,
            notes: replaceStr(isValidated.notes),
            latitude: isValidated.latitude,
            longitude: isValidated.longitude
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'add', `Cast: ${isValidated.castDate}`, props.user.username)

            fetchData() // i need the id if edited
            isChanged.current = false
            closeModal()

          },
          (error) => {

            alert('Error: could not add Cconcrete. Contact and admin.')
            catchError(props.filter.jobNumber, '', props.component, 'addConcrete', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

    if (props.user.concrete < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isValidated.castDate === null || isValidated.castDate === '') {
      alert("Please select a Cast Date.");
    } else if (isValidated.timeCast === null || isValidated.timeCast === '') {
      alert("Please select a Cast Time.");
    } else if (isValidated.fieldMarking === null || isValidated.fieldMarking === '') {
      alert("Please provide a Set No.");
    } else if (isValidated.represents === null || isValidated.represents === '') {
      alert("Please provide an Area of Work.");
    } else if (isValidated.location === null || isValidated.location === '') {
      alert("Please provide a Sample Location.");
    } else if (isValidated.structureType === null || isValidated.structureType === '') {
      alert("Please provide a Structure Type.");
    } else if (isValidated.structureType === 'other' && (isValidated.otherStructure === null || isValidated.otherStructure === '')) {
      alert("Please specify other structure.");
    } else if (isValidated.reinforcementType === null || isValidated.reinforcementType === '') {
      alert("Please provide a Reinforcement Type.");
    } else if (isValidated.materialType === null || isValidated.materialType === '') {
      alert("Please provide a Material Type.");
    } else if (isValidated.specimenType === null || isValidated.specimenType === '') {
      alert("Please provide a Specimen Type.");
    } else if (isValidated.sampleType === null || isValidated.sampleType === '') {
      alert("Please provide a Sample Type.");
    } else if (isValidated.concreteSupplier === null || isValidated.concreteSupplier === '') {
      alert("Please provide a Concrete Supplier.");
    } else if (isValidated.batchPlantLocation === null || isValidated.batchPlantLocation === '') {
      alert("Please provide a Batch Plant Location.");
    } else if (isValidated.deliveryTicketNo === null || isValidated.deliveryTicketNo === '') {
      alert("Please provide a Delivery Ticket No.");
    } else if (isValidated.mixNo === null || isValidated.mixNo === '') {
      alert("Please provide a Mix No.");
    } else if (isValidated.slumpMeasured === null || isValidated.slumpMeasured === '') {
      alert("Please provide a Measured Slump.");
    } else if (isValidated.waterAdded === null || isValidated.waterAdded === '') {
      alert("Please provide Water Added.");
    // } else if (isValidated.airContent === null || isValidated.airContent === '') {
    //   alert("Please provide an Air Content.");
    } else if (isValidated.concreteTemp === null || isValidated.concreteTemp === '') {
      alert("Please provide a Concrete Temp.");
    } else if (isValidated.airTemp === null || isValidated.airTemp === '') {
      alert("Please provide an Air Temp.");
    } else if (isValidated.designStrength === null || isValidated.designStrength === '') {
      alert("Please provide a Design Strength.");
    } else if (isValidated.designStrengthDay === null || isValidated.designStrengthDay === '') {
      alert("Please provide a Design Strenth @ Day.");
    } else if (isValidated.age1 === null || isValidated.age1 === '') {
      alert("Please provide at least one age for breaking.");
    } else {

      if (props.user.bufferData) {

        let id = Math.max(...fetchedData.map(o => o.id)) + 1

        addPending({
          actionId: 1,
          action: 'addConcrete',
          table: 'Concrete',
          jobnumber: props.filter.jobNumber,
          entryby: props.user.username,
          entrytime: formatDateTime(new Date()),
          entrylat: '',
          entrylng: '',
          entrydevice: props.user.device,
          modby: '',
          modtime: null,
          modlat: '',
          modlng: '',
          moddevice: '',
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: '',
          lng: '',
          device: props.user.device,
          id: id
        })
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(prevState =>
              [
                {
                  entryby: props.user.username,
                  entrytime: formatDateTime(new Date()),
                  entrylat: '',
                  entrylng: '',
                  entrydevice: props.user.device,
                  id: id,
                  jobNumber: props.filter.jobNumber,
                  castDate: isValidated.castDate,
                  location: replaceStr(isValidated.location),
                  materialType: isValidated.materialType,
                  specimenType: isValidated.specimenType,
                  sampleType: isValidated.sampleType,
                  diameter: isValidated.diameter,
                  designStrength: isValidated.designStrength,
                  slumpMeasured: isValidated.slumpMeasured,
                  mixNo: isValidated.mixNo,
                  concreteSupplier: isValidated.concreteSupplier,
                  batchPlantLocation: isValidated.batchPlantLocation,
                  deliveryTicketNo: isValidated.deliveryTicketNo,
                  timeCast: isValidated.timeCast,
                  waterAdded: isValidated.waterAdded,
                  airContent: isValidated.airContent,
                  concreteTemp: isValidated.concreteTemp,
                  airTemp: isValidated.airTemp,
                  notes: isValidated.notes
                },
                ...prevState
              ]
            )

            isChanged.current = false
            closeModal()

          },
          (error) => {

            addToServer()
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addConcreteOffline', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      } else {

        addToServer()

      }

    }

  }

  const editData = () => {

    const addToServer = () => {

      getLocation(function(latlng){

        fetch('/api/editConcrete', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            id: isValidated.id,
            jobNumber: props.filter.jobNumber,
            castDate: isValidated.castDate,
            timeCast: isValidated.timeCast,
            fieldMarking: isValidated.fieldMarking,
            represents: replaceStr(isValidated.represents),
            location: replaceStr(isValidated.location),
            structureType: isValidated.structureType,
            otherStructure: replaceStr(isValidated.otherStructure),
            reinforcementType: isValidated.reinforcementType,
            materialType: isValidated.materialType,
            specimenType: isValidated.specimenType,
            sampleType: isValidated.sampleType,
            mixNo: isValidated.mixNo,
            concreteSupplier: replaceStr(isValidated.concreteSupplier),
            batchPlantLocation: isValidated.batchPlantLocation,
            deliveryTicketNo: isValidated.deliveryTicketNo,
            slumpMeasured: isValidated.slumpMeasured,
            waterAdded: isValidated.waterAdded,
            airContent: isValidated.airContent,
            concreteTemp: isValidated.concreteTemp,
            airTemp: isValidated.airTemp,
            designStrength: isValidated.designStrength,
            designStrengthDay: isValidated.designStrengthDay,
            age1: isValidated.age1,
            age2: isValidated.age2,
            age3: isValidated.age3,
            age4: isValidated.age4,
            age5: isValidated.age5,
            age6: isValidated.age6,
            notes: replaceStr(isValidated.notes),
            latitude: isValidated.latitude,
            longitude: isValidated.longitude
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                jobNumber: props.filter.jobNumber,
                castDate: isValidated.castDate,
                timeCast: isValidated.timeCast,
                fieldMarking: isValidated.fieldMarking,
                represents: replaceStr(isValidated.represents),
                location: replaceStr(isValidated.location),
                structureType: isValidated.structureType,
                otherStructure: replaceStr(isValidated.otherStructure),
                reinforcementType: isValidated.reinforcementType,
                materialType: isValidated.materialType,
                specimenType: isValidated.specimenType,
                sampleType: isValidated.sampleType,
                mixNo: isValidated.mixNo,
                concreteSupplier: replaceStr(isValidated.concreteSupplier),
                batchPlantLocation: isValidated.batchPlantLocation,
                deliveryTicketNo: isValidated.deliveryTicketNo,
                slumpMeasured: isValidated.slumpMeasured,
                waterAdded: isValidated.waterAdded,
                airContent: isValidated.airContent,
                concreteTemp: isValidated.concreteTemp,
                airTemp: isValidated.airTemp,
                designStrength: isValidated.designStrength,
                designStrengthDay: isValidated.designStrengthDay,
                age1: isValidated.age1,
                age2: isValidated.age2,
                age3: isValidated.age3,
                age4: isValidated.age4,
                age5: isValidated.age5,
                age6: isValidated.age6,
                notes: replaceStr(isValidated.notes),
                latitude: isValidated.latitude,
                longitude: isValidated.longitude
              } :
              data
            ))

            addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'edit', `Cast: ${isValidated.castDate}`, props.user.username)

            isChanged.current = false
            closeModal()

          },
          (error) => {

            alert('Error: could not edit lab. Contact and admin.')
            catchError(props.filter.jobNumber, '', props.component, 'editConcrete', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

    // if (props.user.concrete < 2) {
    //   alert('You do not have the required permission. Contact an admin.')
    if (isModal.warning > 1) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')
    } else if (isValidated.castDate === null || isValidated.castDate === '') {
      alert("Please select a Cast Date.");
    } else if (isValidated.timeCast === null || isValidated.timeCast === '') {
      alert("Please select a Cast Time.");
    } else if (isValidated.fieldMarking === null || isValidated.fieldMarking === '') {
      alert("Please provide a Set No.");
    } else if (isValidated.represents === null || isValidated.represents === '') {
      alert("Please provide an Area of Work.");
    } else if (isValidated.location === null || isValidated.location === '') {
      alert("Please provide a Sample Location.");
    } else if (isValidated.structureType === null || isValidated.structureType === '') {
      alert("Please provide a Structure Type.");
    } else if (isValidated.structureType === 'other' && (isValidated.otherStructure === null || isValidated.otherStructure === '')) {
      alert("Please specify other structure.");
    } else if (isValidated.reinforcementType === null || isValidated.reinforcementType === '') {
      alert("Please provide a Reinforcement Type.");
    } else if (isValidated.materialType === null || isValidated.materialType === '') {
      alert("Please provide a Material Type.");
    } else if (isValidated.specimenType === null || isValidated.specimenType === '') {
      alert("Please provide a Specimen Type.");
    } else if (isValidated.sampleType === null || isValidated.sampleType === '') {
      alert("Please provide a Sample Type.");
    } else if (isValidated.concreteSupplier === null || isValidated.concreteSupplier === '') {
      alert("Please provide a Concrete Supplier.");
    } else if (isValidated.batchPlantLocation === null || isValidated.batchPlantLocation === '') {
      alert("Please provide a Batch Plant Location.");
    } else if (isValidated.deliveryTicketNo === null || isValidated.deliveryTicketNo === '') {
      alert("Please provide a Delivery Ticket No.");
    } else if (isValidated.mixNo === null || isValidated.mixNo === '') {
      alert("Please provide a Mix No.");
    } else if (isValidated.slumpMeasured === null || isValidated.slumpMeasured === '') {
      alert("Please provide a Measured Slump.");
    } else if (isValidated.waterAdded === null || isValidated.waterAdded === '') {
      alert("Please provide Water Added.");
    // } else if (isValidated.airContent === null || isValidated.airContent === '') {
    //   alert("Please provide an Air Content.");
    } else if (isValidated.concreteTemp === null || isValidated.concreteTemp === '') {
      alert("Please provide a Concrete Temp.");
    } else if (isValidated.airTemp === null || isValidated.airTemp === '') {
      alert("Please provide an Air Temp.");
    } else if (isValidated.designStrength === null || isValidated.designStrength === '') {
      alert("Please provide a Design Strength.");
    } else if (isValidated.designStrengthDay === null || isValidated.designStrengthDay === '') {
      alert("Please provide a Design Strenth @ Day.");
    } else if (isValidated.age1 === null || isValidated.age1 === '') {
      alert("Please provide at least one age for breaking.");
    } else {

      if (props.user.bufferData) {

        addPending({
          actionId: 2,
          action: 'editConcrete',
          table: 'Concrete',
          jobnumber: props.filter.jobNumber,
          gradeid: props.filter.gradeId,
          entryby: props.user.username,
          entrytime: formatDateTime(new Date()),
          entrylat: '',
          entrylng: '',
          entrydevice: props.user.device,
          modby: '',
          modtime: null,
          modlat: '',
          modlng: '',
          moddevice: '',
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: '',
          lng: '',
          device: props.user.device,
          id: isValidated.id
        })
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                moddevice: props.user.device
              } :
              data
            ))

            isChanged.current = false
            closeModal()

          },
          (error) => {

            addToServer()
            catchError(props.filter.jobNumber, '', props.component, 'editConcreteOffline', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      } else {

        addToServer()

      }

    }

  }

  const deleteData = () => {

    const addToServer = () => {

      fetch('/api/deleteConcrete', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: isValidated.id
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))

          addActivity('field', props.filter.jobNumber, props.filter.gradeId, props.component, 'delete', `Cast: ${isValidated.castDate}`, props.user.username)

          //fetchData()
          setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
          isChanged.current = false
          closeModal()

        },
        (error) => {

          alert('Error: could not delete. Contact and admin.')
          catchError(props.filter.jobNumber, '', props.component, 'deleteConcrete', JSON.stringify(error), props.user.username, props.user.device)
        }
      )

    }

    // if (props.user.concrete < 3) {
    //   alert('You do not have the required permission. Contact an admin.')
    if (isModal.warning > 0) {
      alert('You do not have the required permission. Contact an admin.')
    } else {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

        if (props.user.bufferData) {

          addPending({
            actionId: 3,
            action: 'deleteConcrete',
            table: 'Lab',
            id: isValidated.id
          })
          .then(
            (result) => {
              //console.log('result: ' + JSON.stringify(result))

              //fetchData()
              setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
              isChanged.current = false
              closeModal()
              //alert('Deleted.')

            },
            (error) => {

              addToServer()
              catchError(props.filter.jobNumber, '', props.component, 'deleteConcreteOffline', JSON.stringify(error), props.user.username, props.user.device)
            }
          )

        } else {

          addToServer()

        }

      }

    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const openAdd = () => {

    if (props.user.concrete < 2) {

      alert('You do not have the required permission. Contact an admin.')

    } else if (props.filter.jobNumber === '') {

      alert('Please select a JN.')

    } else {

      setIsModal(prevState => ({...prevState, add: true}))

    }

  }

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const openLocate = () => setIsModal(prevState => ({...prevState, locate: true}))

  const closeLocate = () => setIsModal(prevState => ({...prevState, locate: false}))

  const toggleDelivered = () => setIsModal(prevState => ({...prevState, delivered: isModal.delivered ? false : true}))

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false}))
        clearIsValidated()
        isChanged.current = false
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      clearIsValidated()
    }

  }

  let listOfData = fetchedData.map((data, i) => {

    let castDate = data.castDate === null ? '' : data.castDate
    let fieldMarking = data.fieldMarking === null ? '' : data.fieldMarking
    let location = data.location === null ? '' : data.location
    let represents = data.represents === null ? '' : data.represents
    let materialType = data.materialType === null ? '' : data.materialType
    let specimenType = data.specimenType === null ? '' : data.specimenType
    let sampleType = data.sampleType === null ? '' : data.sampleType
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : ''
    let delivered = (data.deliveredtime !== '' && data.deliveredtime !== null) || data.entrytime === '' || data.entrytime === null ? true : false

    let filter = filterData(data, searchValue)

    if (filter) {

      return (
        <tr key={data.id.toString()} data-id={data.id} onClick={selectRow}>
          <td style={{display: 'none'}}>{i}</td>
          {isModal.delivered ?
            <td>
              <Icon
                name={delivered ? 'check_circle' : 'panorama_fish_eye'}
                color={delivered ? 'dodgerblue' : 'tomato'}
                i={i}

              />
            </td> :
            <td>
              <Icon
                name='local_shipping'
                color={delivered ? 'dodgerblue' : 'tomato'}
                outline={delivered ? false : true}
                i={i}
              />
            </td>
          }
          <td>{castDate}</td>
          <td>{fieldMarking}</td>
          <td className='wrapText'>{location}</td>
          <td>{materialType}</td>
          <td>{specimenType}</td>
          <td>{sampleType}</td>
          <td>{entryBy}</td>
        </tr>
      )

    }

    else {

      console.log('hide me?')
      //if (isModal.google) updateMap(data.id, false)
    }

  })

  let breakDays = (
    <>
      <option value=""></option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="7">7</option>
      <option value="14">14</option>
      <option value="28">28</option>
      <option value="0">Hold</option>
    </>
  )

  let modalContent = (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

      <div style={{display: 'inline-block', textAlign: 'right', margin: 10}}>

        <div>
          <label className='label'>Cast Date</label>
          <input className='input' type="date" pattern=".{1,}" name='castDate' onInput={validate} onChange={changedData} defaultValue={isValidated.castDate === '' || isValidated.castDate === null ? null : isValidated.castDate} required />
        </div>

        <div>
          <label className='label'>Cast Time</label>
          <input style={{width: 100}} className='input' type="time" pattern=".{1,}" name='timeCast' onInput={validate} onChange={changedData} defaultValue={isValidated.timeCast} required />
        </div>

        <div>
          <label className='label'>Set No</label>
          <input style={{width: 75}} className='input' type="text" pattern="[0-9]{1,}" name='fieldMarking' onInput={validate} onChange={changedData} defaultValue={isValidated.fieldMarking} required />
        </div>

        <div>
          <label className='label'>Area of Work</label>
          <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" placeholder='Gridlines, etc.' name='represents' onInput={validate} onChange={changedData} required>{isValidated.represents}</textarea>
        </div>

        <div>
          <label className='label'>Sample Location</label>
          <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" placeholder='Location within Area of Work specimen was sampled' name='location' onInput={validate} onChange={changedData} required>{isValidated.location}</textarea>
        </div>

        <div>
          <label className='label'>Structure Type</label>
          <select className='select' pattern=".{1,}" name='structureType' onInput={validate} onChange={changedData} defaultValue={isValidated.structureType} required>
            <option value=""></option>
            <option value="slabOnGrade">Slab on Grade</option>
            <option value="elevatedSlab">Elevated Slab</option>
            <option value="footing">Footing</option>
            <option value="pile">Pile</option>
            <option value="pier">Pier</option>
            <option value="column">Column</option>
            <option value="wallPanel">Wall Panel</option>
            <option value="beam">Beam</option>
            <option value="sidewalk">Sidewalk</option>
            <option value="curbAndGutter">Curb and Gutter</option>
            <option value="pavement">Pavement</option>
            <option value="masonry">Masonry</option>
            <option value="other">Other</option>
          </select>
        </div>

        {isValidated.structureType === 'other' ?
          <div>
            <textarea className='textArea' pattern="{1,}" placeholder='Specify other' name='otherStructure' onInput={validate} onChange={changedData} required>{isValidated.otherStructure}</textarea>
          </div> : null
        }

        <div>
          <label className='label'>Reinforcement Type</label>
          <select className='select' pattern=".{1,}" name='reinforcementType' onInput={validate} onChange={changedData} defaultValue={isValidated.reinforcementType} required>
            <option value=""></option>
            <option value="non">Non-Reinforced</option>
            <option value="conv">Conv. Reinforced</option>
            <option value="pt">Post-tension</option>
            <option value="pre">Pre-tension</option>
          </select>
        </div>

        <div>
          <label className='label'>Material Type</label>
          <select className='select' pattern=".{1,}" name='materialType' onInput={validate} onChange={changedData} defaultValue={isValidated.materialType} required>
            <option value=""></option>
            <option value="Concrete">Concrete Reg</option>
            <option value="lightWeight">Concrete LW</option>
            <option value="shotcrete">Shotcrete</option>
            <option value="Grout">Grout</option>
            <option value="nsGrout">Non-Shrink Grout</option>
            <option value="Mortar">Mortar</option>
            <option value="Prism">Prism</option>
          </select>
        </div>

        <div>
          <label className='label'>Specimen Type</label>
          <select className='select' pattern=".{1,}" name='specimenType' onInput={validate} onChange={changedData} defaultValue={isValidated.specimenType} required>
            <option value=""></option>
            <option value="Cylinder">Cylinder</option>
            <option value="Cube">Cube</option>
            <option value="Prism">Prism</option>
          </select>
        </div>

        <div>
          <label className='label'>Sample Type</label>
          <select className='select' pattern=".{1,}" name='sampleType' onInput={validate} onChange={changedData} defaultValue={isValidated.sampleType} required>
            <option value=""></option>
            <option value="cast">Cast</option>
            <option value="cored">Cored</option>
          </select>
        </div>

        <div>
          <label className='label'>Supplier</label>
          <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='concreteSupplier' onInput={validate} onChange={changedData} required>{isValidated.concreteSupplier}</textarea>
        </div>

        <div>
          <label className='label'>Batch</label>
          <input style={{width: 75}} className='input' type="text" pattern="[a-zA-Z0-9]{1,}" name='batchPlantLocation' onInput={validate} onChange={changedData} defaultValue={isValidated.batchPlantLocation} required />
        </div>

        <div>
          <label className='label'>Ticket No</label>
          <input style={{width: 100}} className='input' type="text" pattern="[a-zA-Z0-9]{1,}" name='deliveryTicketNo' onInput={validate} onChange={changedData} defaultValue={isValidated.deliveryTicketNo} required />
        </div>

        <div>
          <label className='label'>Mix No</label>
          <input style={{width: 100}} className='input' type="text" pattern="[a-zA-Z0-9]{1,}" name='mixNo' onInput={validate} onChange={changedData} defaultValue={isValidated.mixNo} required />
        </div>

        <div>
          <label className='label'>Slump Measured (in)</label>
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1,2})?" name='slumpMeasured' onInput={validate} onChange={changedData} defaultValue={isValidated.slumpMeasured} required />
        </div>

        <div>
          <label className='label'>Water Added (gal/load)</label>
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1,2})?" name='waterAdded' onInput={validate} onChange={changedData} defaultValue={isValidated.waterAdded} required />
        </div>

        <div>
          <label className='label'>Air Content (%)</label>
          <input style={{width: 75}} className='input' type="text" placeholder='Optional' pattern="\d{1,3}(\.\d{1,2})?" name='airContent' onInput={validate} onChange={changedData} defaultValue={isValidated.airContent} required />
        </div>

        <div>
          <label className='label'>Conc. Temp (F)</label>
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1,2})?" name='concreteTemp' onInput={validate} onChange={changedData} defaultValue={isValidated.concreteTemp} required />
        </div>

        <div>
          <label className='label'>Air Temp (F)</label>
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1,2})?" name='airTemp' onInput={validate} onChange={changedData} defaultValue={isValidated.airTemp} required />
        </div>

        <div>
          <label className='label'>Specified Strength (psi)</label>
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,}" name='designStrength' onInput={validate} onChange={changedData} defaultValue={isValidated.designStrength} required />
        </div>

        <div>
          <label className='label'>@ (days)</label>
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}" name='designStrengthDay' onInput={validate} onChange={changedData} defaultValue={isValidated.designStrengthDay} required />
        </div>

        <div>
          <label className='label'>Break (days)</label>
          <select className='select' pattern=".{1,}" name='age1' onInput={validate} onChange={changedData} defaultValue={isValidated.age1} required>
            {breakDays}
          </select>

          <select className='select' pattern=".{1,}" name='age2' onInput={validate} onChange={changedData} defaultValue={isValidated.age2} required>
            {breakDays}
          </select>

          <select className='select' pattern=".{1,}" name='age3' onInput={validate} onChange={changedData} defaultValue={isValidated.age3} required>
            {breakDays}
          </select>

          <select className='select' pattern=".{1,}" name='age4' onInput={validate} onChange={changedData} defaultValue={isValidated.age4} required>
            {breakDays}
          </select>

          <select className='select' pattern=".{1,}" name='age5' onInput={validate} onChange={changedData} defaultValue={isValidated.age5} required>
            {breakDays}
          </select>

          <select className='select' pattern=".{1,}" name='age6' onInput={validate} onChange={changedData} defaultValue={isValidated.age6} required>
            {breakDays}
          </select>
        </div>

        <div style={{width: '100%', height: '100%', textAlign: 'center', borderTop: '1px solid black'}}>

          <small>Optional</small>
          <p><b>Specify an onsite location for pickup by clicking the <span style={{color: 'dodgerblue'}}>Blue</span> button and/or add Notes below</b></p>

          <CircleButton iconName='add_location' onClick={openLocate} /><br />

          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {isValidated.latitude !== null && isValidated.latitude !== '' ? <Icon name='where_to_vote' outline={true} /> : <Icon name='not_listed_location' color='tomato' outline={true} />}
            <span style={{verticalAlign: 'base-line', color: isValidated.latitude !== null && isValidated.latitude !== '' ? 'dodgerblue' : 'tomato'}}>Lat/Lng</span>
          </div>

          <div>
            <label className='label'>Notes</label>
            <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" placeholder='Where did you leave the specimen?' name='notes' onInput={validate} onChange={changedData} required>{isValidated.notes}</textarea>
          </div>

        </div>

      </div>

    </div>
  )

  const comingSoon = () => alert('Coming soon')

  return (
    props.filter.jobNumber !== null && props.filter.jobNumber !== '' ?
    <>
      {isModal.add || isModal.edit ? <Modal add={isModal.add ? addData : isModal.edit ? editData : null} delete={isModal.edit ? deleteData : null} content={modalContent} closeModal={closeModal} isValidated={isValidated} isModal={isModal} /> : null}
      {isModal.locate ? <Locate close={closeLocate} add={selectLocate} filter={props.filter} latlng={isValidated} /> : null}
      {!isModal.loading ?
        <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

          <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

            <div style={{width: '100%'}}>

              {props.user.userlevel === 'guest' || props.user.concrete < 2 ? null : props.user.device === 'desktop' ? <Icon name='add_circle' onClick={openAdd} /> : <AddButton onClick={openAdd} />}
              {props.user.userlevel === 'guest' || props.user.concrete < 2 ? null : <Icon name='local_shipping' color={isModal.delivered ? 'dodgerblue' : 'gray'} onClick={toggleDelivered} />}
              <Icon name='refresh' onClick={fetchData} />

            </div>

            <SearchBar search={search} clearSearch={clearSearch} />

            {fetchedData.length > 0 ?

              <div style={{flex: '1', overflow: 'auto'}}>

                <table>

                  <thead>
                    <tr>
                      {isModal.delivered ? <th>Delivered?</th> : <th></th>}
                      <th>Cast</th>
                      <th>Set</th>
                      <th>Location</th>
                      <th>Material</th>
                      <th>Specimen</th>
                      <th>Sample</th>
                      <th>By</th>
                    </tr>
                  </thead>

                  <tbody>
                    {listOfData}
                  </tbody>

                </table>

              </div> :
              <p style={{margin: 10}}>No data found.</p>

            }

          </div>

        </div>:
        <p style={{margin: 10}}>Loading...</p>

      }
    </> :
    <p style={{margin: 10}}>Select a Job.</p>
  )

}

export default Concrete
