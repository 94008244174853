import React from 'react'


//import { formatDateYMD, formatDateTime, catchError, getLocation } from 'scripts/common'

const MaterialBreaks = (props) => {

  let strength1 = ''
  let strength2 = ''
  let strength3 = ''
  let strength4 = ''
  let strength5 = ''
  let strength6 = ''
  let percent1 = ''
  let percent2 = ''
  let percent3 = ''
  let percent4 = ''
  let percent5 = ''
  let percent6 = ''

  // L/D factor: y = -0.16x2 + 0.584x + 0.447
  // determined from ASTM C39 and using excel to obtain the formula for y ( factor)

  if (props.data !== null) {

    if (props.data.diameter !== '') {

      let diameter = Number(props.data.diameter)

      let factor1 = props.data.diameter !== '' && props.data.length1 !== '' ? Number(props.data.length1) / diameter >= 1.75 ? 1 : -0.16 * (Number(props.data.length1) / diameter) ** 2 + 0.584 * (Number(props.data.length1) / diameter) + 0.447 : 0
      let factor2 = props.data.diameter !== '' && props.data.length2 !== '' ? Number(props.data.length2) / diameter >= 1.75 ? 1 : -0.16 * (Number(props.data.length2) / diameter) ** 2 + 0.584 * (Number(props.data.length2) / diameter) + 0.447 : 0
      let factor3 = props.data.diameter !== '' && props.data.length3 !== '' ? Number(props.data.length3) / diameter >= 1.75 ? 1 : -0.16 * (Number(props.data.length3) / diameter) ** 2 + 0.584 * (Number(props.data.length3) / diameter) + 0.447 : 0
      let factor4 = props.data.diameter !== '' && props.data.length4 !== '' ? Number(props.data.length4) / diameter >= 1.75 ? 1 : -0.16 * (Number(props.data.length4) / diameter) ** 2 + 0.584 * (Number(props.data.length4) / diameter) + 0.447 : 0
      let factor5 = props.data.diameter !== '' && props.data.length5 !== '' ? Number(props.data.length5) / diameter >= 1.75 ? 1 : -0.16 * (Number(props.data.length5) / diameter) ** 2 + 0.584 * (Number(props.data.length5) / diameter) + 0.447 : 0
      let factor6 = props.data.diameter !== '' && props.data.length6 !== '' ? Number(props.data.length6) / diameter >= 1.75 ? 1 : -0.16 * (Number(props.data.length6) / diameter) ** 2 + 0.584 * (Number(props.data.length6) / diameter) + 0.447 : 0

      // console.log(`
      //   diameter: ${diameter}
      //   L/D: ${(Number(props.data.length1) / diameter)}
      //   factor:
      //   1 raw: ${(-0.16) * (Number(props.data.length1) / diameter) ** 2}
      //   1 raw: ${(Number(props.data.length1) / diameter) ** 2}
      //   1 raw: ${0.584 * (Number(props.data.length1) / diameter)}
      //   1 raw: ${0.447}
      //   1 raw: ${0.584 * (Number(props.data.length1) / diameter) + 0.447}
      //   1: ${factor1}
      //   2: ${factor2}
      //   3: ${factor3}
      //   4: ${factor4}
      //   5: ${factor5}
      //   6: ${factor6}
      // `)

      strength1 = props.data.maxLoad1 === '' ? '' : Math.round((4 * Number(props.data.maxLoad1)) / (Math.PI * diameter ** 2) * factor1)
      strength2 = props.data.maxLoad2 === '' ? '' : Math.round((4 * Number(props.data.maxLoad2)) / (Math.PI * diameter ** 2) * factor2)
      strength3 = props.data.maxLoad3 === '' ? '' : Math.round((4 * Number(props.data.maxLoad3)) / (Math.PI * diameter ** 2) * factor3)
      strength4 = props.data.maxLoad4 === '' ? '' : Math.round((4 * Number(props.data.maxLoad4)) / (Math.PI * diameter ** 2) * factor4)
      strength5 = props.data.maxLoad5 === '' ? '' : Math.round((4 * Number(props.data.maxLoad5)) / (Math.PI * diameter ** 2) * factor5)
      strength6 = props.data.maxLoad6 === '' ? '' : Math.round((4 * Number(props.data.maxLoad6)) / (Math.PI * diameter ** 2) * factor6)

      if (props.data.designStrength !== '') {

        let designStrength = Number(props.data.designStrength)

        percent1 = strength1 !== '' ? Math.round(strength1 / designStrength * 100) : ''
        percent2 = strength2 !== '' ? Math.round(strength2 / designStrength * 100) : ''
        percent3 = strength3 !== '' ? Math.round(strength3 / designStrength * 100) : ''
        percent4 = strength4 !== '' ? Math.round(strength4 / designStrength * 100) : ''
        percent5 = strength5 !== '' ? Math.round(strength5 / designStrength * 100) : ''
        percent6 = strength6 !== '' ? Math.round(strength6 / designStrength * 100) : ''

      }

    }

  }

  return props.data === null ? null : (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

      <div style={{display: 'inline-block', textAlign: 'right', margin: 10}}>

        <div>
          <label className='label'>{props.data.specimenType === 'Prism' ? 'Area (in^2)' : 'Diameter (in)'}</label>
          <input style={{width: 50}} className='input' type="text" pattern="\d{1,}(\.\d{1,})?" name='diameter' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.diameter} required />
        </div>

        <div>
          <label className='label'>Design Strength (psi)</label>
          <input style={{width: 50}} className='input' type="text" value={props.data.designStrength} disabled />
        </div>

        <div>
          <label className='label' style={{width: 50}}>Day</label>
          <label className='label' style={{width: 50}}>Length</label>
          <label className='label' style={{width: 50}}>Load</label>
          <label className='label' style={{width: 50}}>Type</label>
          <label className='label' style={{width: 50}}>Strength</label>
          <label className='label' style={{width: 50}}>%</label>
        </div>

        <div>
          <label className='label'>1</label>
          <input style={{width: 50}} className='input' type="text" value={props.data.age1} disabled />
          <input style={{width: 50}} className='input' type="text" pattern="\d{1,}(\.\d{1,})?" name='length1' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.length1} required />
          <input style={{width: 50}} className='input' type="text" pattern="\d{1,}" name='maxLoad1' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.maxLoad1} required />
          <input style={{width: 50}} className='input' type="text" pattern="\d{1}" name='breakType1' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.breakType1} required />
          <input style={{width: 50}} className='input' type="text" value={strength1} disabled />
          <input style={{width: 50}} className='input' type="text" value={percent1} disabled />
        </div>

        <div>
          <label className='label'>2</label>
          <input style={{width: 50}} className='input' type="text" value={props.data.age2} disabled />
          <input style={{width: 50}} className='input' type="text" pattern="\d{1,}(\.\d{1,})?" name='length2' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.length2} required />
          <input style={{width: 50}} className='input' type="text" pattern="\d{1,}" name='maxLoad2' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.maxLoad2} required />
          <input style={{width: 50}} className='input' type="text" pattern="\d{1}" name='breakType2' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.breakType2} required />
          <input style={{width: 50}} className='input' type="text" value={strength2} disabled />
          <input style={{width: 50}} className='input' type="text" value={percent2} disabled />
        </div>

        <div>
          <label className='label'>3</label>
          <input style={{width: 50}} className='input' type="text" value={props.data.age3} disabled />
          <input style={{width: 50}} className='input' type="text" pattern="\d{1,}(\.\d{1,})?" name='length3' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.length3} required />
          <input style={{width: 50}} className='input' type="text" pattern="\d{1,}" name='maxLoad3' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.maxLoad3} required />
          <input style={{width: 50}} className='input' type="text" pattern="\d{1}" name='breakType3' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.breakType3} required />
          <input style={{width: 50}} className='input' type="text" value={strength3} disabled />
          <input style={{width: 50}} className='input' type="text" value={percent3} disabled />
        </div>

        <div>
          <label className='label'>4</label>
          <input style={{width: 50}} className='input' type="text" value={props.data.age4} disabled />
          <input style={{width: 50}} className='input' type="text" pattern="\d{1,}(\.\d{1,})?" name='length4' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.length4} required />
          <input style={{width: 50}} className='input' type="text" pattern="\d{1,}" name='maxLoad4' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.maxLoad4} required />
          <input style={{width: 50}} className='input' type="text" pattern="\d{1}" name='breakType4' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.breakType4} required />
          <input style={{width: 50}} className='input' type="text" value={strength4} disabled />
          <input style={{width: 50}} className='input' type="text" value={percent4} disabled />
        </div>

        <div>
          <label className='label'>5</label>
          <input style={{width: 50}} className='input' type="text" value={props.data.age5} disabled />
          <input style={{width: 50}} className='input' type="text" pattern="\d{1,}(\.\d{1,})?" name='length5' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.length5} required />
          <input style={{width: 50}} className='input' type="text" pattern="\d{1,}" name='maxLoad5' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.maxLoad5} required />
          <input style={{width: 50}} className='input' type="text" pattern="\d{1}" name='breakType5' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.breakType5} required />
          <input style={{width: 50}} className='input' type="text" value={strength5} disabled />
          <input style={{width: 50}} className='input' type="text" value={percent5} disabled />
        </div>

        <div>
          <label className='label'>6</label>
          <input style={{width: 50}} className='input' type="text" value={props.data.age6} disabled />
          <input style={{width: 50}} className='input' type="text" pattern="\d{1,}(\.\d{1,})?" name='length6' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.length6} required />
          <input style={{width: 50}} className='input' type="text" pattern="\d{1,}" name='maxLoad6' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.maxLoad6} required />
          <input style={{width: 50}} className='input' type="text" pattern="\d{1}" name='breakType6' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.breakType6} required />
          <input style={{width: 50}} className='input' type="text" value={strength6} disabled />
          <input style={{width: 50}} className='input' type="text" value={percent6} disabled />
        </div>

      </div>

    </div>
  )

}

export default MaterialBreaks
